<div class="mat-modal-dialog add-outsourceveh-modal">
    <h2 mat-dialog-title>
        <mat-icon style="color:#FF0000; float: right; cursor: pointer; position: relative; top: 7px;"
            (click)="onClickCancel()">close</mat-icon>
    </h2>
    <mat-dialog-content style="position: relative; top: -25px;">
        <span class="material-icons-outlined"
            style="color: #FF0000; font-size: 19px; position: relative; top: 5px; margin-right: 10px;">
            info</span> <span style="color: #FF455B; font-size: 14px; font-weight: 500; font-family: poppins;">Add
            Ad-Hoc
            Vehicle.</span>
    </mat-dialog-content>

    <mat-dialog-content style="position: relative; bottom: 30px;">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <div class="row">
                    <div class="col-sm-4 col-md-4">
                        <label class="font-size-font" style="color: #000000;">Registration No<sup
                                class="mandatory">*</sup></label>
                        <input class="form-input" style="line-height: 1.5;" aria-label="Default select example"
                            placeholder="Registration No" maxlength="60" autocomplete="off"
                            (keypress)="discard_special_char_And_String($event)" [(ngModel)]="registrationNo">
                    </div>
                    <div class="col-sm-4 col-md-4">
                        <label class="font-size-font" style="color: #000000;">Vehicle Type<sup
                                class="mandatory">*</sup></label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="vehicleType">
                            <option value='' disabled>Choose Vehicle Type</option>
                            <option *ngFor="let vehicleType of _mVehicleTypes" [value]="vehicleType.id">
                                {{vehicleType.vehicleType}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-4 col-md-4">
                        <div class="d-flex" style="position: absolute; top: 30px;">
                            <span
                                style="position: relative; top: 5px; font-size: 14px; font-weight: 500; font-family: 'Poppins'; color: #000000;">Map
                                The Existing Driver</span>
                            <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="mapToExistingDriver"
                                style="position: relative; top: 5px; left: 18px;"
                                (click)="onStatusChange()"></ui-switch>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-4 col-md-4" *ngIf="!mapToExistingDriver">
                        <label class="font-size-font" style="color: #000000;">Driver Name<sup
                                class="mandatory">*</sup></label>
                        <input class="form-input" style="line-height: 1.5;" aria-label="Default select example"
                            placeholder="Enter Name" maxlength="60" type="text" autocomplete="off"
                            (keypress)="discard_special_char_And_String($event)" [(ngModel)]="driverName">
                    </div>
                    <div class="col-sm-4 col-md-4" *ngIf="mapToExistingDriver">
                        <label class="font-size-font" style="color: #000000;">Driver<sup
                                class="mandatory">*</sup></label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="driverName"
                            (change)="onSelectDriver($event)">
                            <option value='' disabled>Choose Driver</option>
                            <option *ngFor="let data of _mDriversData" [value]="data.id">
                                {{data.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-4 col-md-4">
                        <label class="font-size-font" style="color: #000000;">License Number<sup
                                class="mandatory">*</sup></label>
                        <input class="form-input" style="line-height: 1.5;" aria-label="Default select example"
                            placeholder="License Number" [disabled]="mapToExistingDriver" maxlength="60"
                            autocomplete="off" (keypress)="discard_special_char($event)" [(ngModel)]="licenseNumber">
                    </div>
                    <div class="col-sm-4 col-md-4">
                        <label class="font-size-font" style="color: #000000;">Mobile number<sup
                                class="mandatory">*</sup></label>
                        <input class="form-input" style="line-height: 1.5;" aria-label="Default select example"
                            type="text" [disabled]="mapToExistingDriver" maxlength="10"
                            placeholder="Enter Mobile Number" [(ngModel)]="contactNumber"
                            (keypress)="omit_special_char_And_num($event)">
                        <div class="error-msg" *ngIf="contactNumber !== '' && contactNumber.length < 10">
                            Please enter a 10 digit Mobile Number.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions align="end">
        <button class="btn outsource-veh-btn" (click)="onConfirmation()"
            [disabled]="registrationNo == '' || vehicleType == '' || driverName == '' || licenseNumber == '' || (contactNumber == '' && contactNumber.length < 10)">Submit</button>
    </div>
</div>