<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="add-btn-property">
                        <button type="button" class="btn add-btn-outline1" (click)="addTrip()">
                            <em class="bx bx-plus me-1"></em>Add
                        </button>
                    </div>
                    <ul ngbNav #nav="ngbNav" [activeId]="activeTabId"
                        class="nav-tabs nav-tabs-custom g-nav-tabs-custom">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Trips</a>
                            <ng-template ngbNavContent>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="btn-property d-flex">
                                            <div>
                                                <button class="btn btn-red me-3" (click)="removeDel()">
                                                    Remove Cancelled
                                                </button>
                                            </div>


                                            <button class="btn btn-red me-3" (click)="preViewMap()">
                                                <img src="assets/images/refresh.svg" class="me-2"
                                                    ngbTooltip="Refresh Map">Preview
                                            </button>
                                            <button class="btn btn-red me-3" (click)="onClickMap()">
                                                <img src={{icon}} class="me-2" ngbTooltip={{title}}>Map
                                            </button>
                                            <button class="btn red-btn-solid" (click)="autoSeqDialog()">Save</button>
                                        </div>
                                        <div class="d-flex">
                                            <span class="text-menu me-1" (click)="onClickBack()"
                                                style="cursor: pointer; margin-left: -13px;"> <em
                                                    class="mdi mdi-arrow-left me-2"></em>Trips</span>
                                            <span class="text-submenu">/ Trip Modification</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3 gx-5">
                                    <div class="col-sm-6 col-md-6">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12" [ngClass]="{'mapDiv': height == '400px'}">
                                                <div id="map" [style.height]="height"></div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-sm-6 col-md-6">
                                                <h4 class="heading">Exception Employees</h4>
                                            </div>
                                            <div class="col-sm-6 col-md-6">
                                                <input type="text" class="form-control icon s-input search"
                                                    placeholder="Search by Id/Name" autocomplete="off"
                                                    [(ngModel)]="userSearchkey"
                                                    (input)="searchSubject$.next($event.target.value)">
                                                <mat-option class="font-user" *ngFor="let user of exceptionUsersList"
                                                    [value]="user.userInfoDetails.displayName"
                                                    (onSelectionChange)="searchUserSubmit(user)">
                                                    {{user.userInfoDetails.employeeId }} - {{
                                                    user.userInfoDetails.displayName }}
                                                </mat-option>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-sm-12 col-md-12 vendor-list" [style.height]="panelBody">
                                                <div dnd-sortable-container [sortableData]="exceptionEmps">
                                                    <ul class="list-unstyled">
                                                        <li *ngFor="let user of exceptionEmps; let i= index"
                                                            class="list-group-item" dnd-sortable [dragData]="user"
                                                            [sortableIndex]="i" (onDropSuccess)="onExceptionDrop(user)"
                                                            (onDragStart)="exceptionDragStarted(user,i)">
                                                            <div class="row p-2 py-2">
                                                                <div class="col-1">
                                                                    <img src="assets/images/livetracking/male.png"
                                                                        *ngIf="user.tripUser.userInfoDetails.gender == 'Male'"
                                                                        title="Male" class="img">
                                                                    <img src="assets/images/livetracking/female.png"
                                                                        *ngIf="user.tripUser.userInfoDetails.gender == 'Female'"
                                                                        title="Female" class="img">
                                                                </div>

                                                                <div class="col-10 aligntext">
                                                                    <span class="text-black">{{user.tripUser.userInfoDetails.employeeId}}-</span>&nbsp;
                                                                    <span class="text-muted">{{user.tripUser.userInfoDetails.displayName}} - {{user?.landmark?.area.area}}, {{user?.landmark?.landmark}}</span>&nbsp;
                                                                    <span class="text-muted" *ngIf="user.tripCode">- {{user?.tripCode}}</span>
                                                                </div>
                                                                <span class="col-1" *ngIf="user?.userAdded">
                                                                    <img src="assets/images/icons/close icon.jpg"
                                                                        class="img" matTooltip="Remove Employee"
                                                                        (click)="removeExceptionUser(i)">
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6"
                                        style="border: 1.5px solid #CED4DA; border-radius: 5px;">
                                        <div class="row mt-3">
                                            <div class="col-sm-12 col-md-12" style="padding: 0px;">
                                                <input type="text" class="form-control icon s-input search"
                                                    placeholder="Search Routed Users by Name" autocomplete="off"
                                                    (input)="searchSubjectTrip$.next($event.target.value)">
                                                <span *ngIf="!dataFlag">
                                                    <mat-option class="font-user" *ngFor="let user of []"
                                                        [value]="user.userName">
                                                    </mat-option>
                                                </span>
                                                <span *ngIf="dataFlag">
                                                    <mat-option class="font-user" *ngFor="let user of tempData"
                                                        [value]="user.userName"
                                                        (onSelectionChange)="searchSelected(user)">
                                                        {{user.userId }} - {{user.userName }}
                                                    </mat-option>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row vendor mt-3">
                                            <div class="col-sm-12 col-md-12">
                                                <div *ngFor="let trip of tripsList; let d=index"
                                                    style="cursor: pointer;">
                                                    <div class="panel-heading">
                                                        <div class="row" style="display: initial;">
                                                            <span class="text-list">Trip code : {{trip?.tripCode}}
                                                            </span>
                                                            <span class="text-list"
                                                                style="position: relative; left: 10px;">Escort</span>
                                                            <ui-switch defaultBoColor="#dfdfdf" color="#34c38f"
                                                                (click)="escortClick(trip)"
                                                                [checked]="trip.escortStatus"
                                                                (change)="escortChange($event,trip)"
                                                                style="top: 5px; position: relative;left: 10px;"
                                                                [disabled]="trip.tripDetails?.tripUsers.length >= trip?.vehicleCap">
                                                            </ui-switch>

                                                            <!-- <select class="form-select b-0"
                                                        (change)="SelectedVehicle($event.value, trip)"
                                                        [(ngModel)]="trip.vehicleTypeId" required>
                                                        <option value="" disabled>Select Vehicle</option>
                                                        <option *ngFor="let vehicle of vehicleList"
                                                            [value]="vehicle.id"
                                                            [disabled]="vehicle.seatCapacity<trip?.tripDetails?.tripUsers.length"
                                                            title="{{vehicle.seatCapacity<trip.tripDetails?.tripUsers.length ? 'Please remove some employees to select this vehicle!' : ''}}">
                                                            {{ vehicle.vehicleType }}
                                                        </option>
                                                    </select> -->
                                                            <mat-select class="vehicle-list" placeholder="Vehicle"
                                                                style="position: relative; left: 20px;"
                                                                (selectionChange)="SelectedVehicle($event.value, trip)"
                                                                [value]="trip?.vehicleTypeId" required>
                                                                <mat-option class="tripModifyMatoption"
                                                                    *ngFor="let vehicle of vehicleList"
                                                                    [value]="vehicle.id"
                                                                    [disabled]="vehicle.seatCapacity<trip?.tripDetails?.tripUsers.length"
                                                                    [matTooltip]="vehicle.seatCapacity<trip.tripDetails?.tripUsers.length
            ?
            'Please remove some employees to select this vehicle!' : ''">
                                                                    {{ vehicle.vehicleType }}
                                                                </mat-option>
                                                            </mat-select>

                                                            <span class="text-list"
                                                                style="position: relative;left: 40px;">EmpCount:
                                                                {{trip.tripDetails?.tripUsers?.length +
                                                                (trip.escortStatus ? 1: 0)}}
                                                                <span (click)="editZone(trip)" style="cursor: pointer;">
                                                                    <img src="assets/images/icons/edit.svg">
                                                                </span>
                                                            </span>

                                                            <span matTooltip="Edit Zone" matTooltip="Route color"
                                                                style="position: relative; left: 40px; float: none; border-radius: 5px;"
                                                                [style.background-color]="colours[d]"
                                                                svgIcon="stop"></span>
                                                        </div>
                                                    </div>

                                                    <div class="panel-body" dnd-sortable-container
                                                        [sortableData]="trip.tripDetails?.tripUsers">
                                                        <ul class="list-unstyled" style="margin-left: 13px;">
                                                            <!-- <li *ngIf="trip.escortStatus &&
                                                    trip.tripDetails?.tripUsers?.length <trip?.vehicleCap"
                                                                    class="list-group-item"
                                                                    [ngStyle]="{'background':'#FF0000','color':'#fff'}">
                                                                    Trip
                                                                    reached max seat capacity </li> -->
                                                            <li *ngFor="let user of trip.tripDetails?.tripUsers; let i = index"
                                                                (drag)="dragHandler($event)" class="list-group-item"
                                                                (onDragStart)="dragStarted(trip, d, i)"
                                                                (onDropSuccess)="onItemDrop(trip, user, d)"
                                                                dragover="dragEnded(trip, d, i)" [dragEnabled]="true"
                                                                dnd-sortable dnd-droppable [dragData]="trip"
                                                                [sortableIndex]="i">

                                                                <div class="row p-2 py-2"
                                                                    [ngStyle]="{'border': user.showStatus === 'Cancel' ? '2px solid red' : 'none','background-color': user.tripUser.userInfoDetails.displayName === userSelected ? 'yellow' : '#ffffff'}">
                                                                    <div class="col-1">
                                                                        <img src="assets/images/livetracking/male.png"
                                                                            *ngIf="user?.tripUser?.userInfoDetails?.gender === 'Male'"
                                                                            title="Male" class="img">
                                                                        <img src="assets/images/livetracking/female.png"
                                                                            *ngIf="user?.tripUser?.userInfoDetails?.gender === 'Female'"
                                                                            title="Female" class="img">
                                                                    </div>

                                                                    <div class="col-10 aligntext">
                                                                        <span class="text-black">{{i+1}}.
                                                                            P{{trip?.usersList[i]?.routedOrder}}
                                                                            -</span>&nbsp;
                                                                        <span
                                                                            class="text-black">{{user?.tripUser?.userInfoDetails?.employeeId}}
                                                                            -</span>&nbsp;
                                                                        <span
                                                                            class="text-muted">{{user?.tripUser?.userInfoDetails?.displayName}}
                                                                            -</span>&nbsp;
                                                                        <span
                                                                            class="text-muted">{{user?.landmark?.area.area}},
                                                                            {{user?.landmark?.landmark}}</span>
                                                                    </div>

                                                                    <div class="col-1">
                                                                        <span
                                                                            (click)="removeUserFromTrips(trip, user, d)">
                                                                            <img src="assets/images/icons/close icon.jpg"
                                                                                matTooltip="Remove Employee"
                                                                                class="img">
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="p-3"></div>
                </div>
            </div>
        </div>
    </div>
</div>