<div class="container-fluid">
  <div class="row mb-3">
    <div class="d-flex justify-content-between">
      <div>
        <button type="button" style="border: none !important;" class="btn btn-primary1" (click)="onClickBack()">
          <span class="text-menu"><em class="mdi mdi-arrow-left me-1"></em> Back </span>
        </button>
        <span class="ms-3 mt-2 text-submenu">{{_mTitle}}</span>
      </div>
      <div>
        <span *ngIf="fixedRouting" class="trip-info-text"><sup class="mandatory"
            style="position: relative; right: 3px;">*</sup>AL shown are recorded as per the Trip date</span>
        <span *ngIf="!fixedRouting" class="trip-info-text"><sup class="mandatory"
            style="position: relative; right: 3px;">*</sup>Address shown are recorded as per the Trip date</span>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="table-responsive" style="background-color: #F5F7FE;">
        <table class="table table-borderless table-body font-size-14 mb-0">
          <thead>
            <tr>
              <th>Schedule Count</th>
              <th>Routed Count</th>
              <th>Trips</th>
              <th *ngFor="let vehicleType of tripSummary?.vehicleTypes">{{vehicleType.vehicleType}}</th>
              <th>Security</th>
              <th>Shift</th>
              <th>Female</th>
              <th>Occupancy</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{{tripSummary?.BOOKING ? tripSummary?.BOOKING : 0}}</td>
              <td>{{tripSummary?.ROUTEDCOUNT ? tripSummary?.ROUTEDCOUNT : 0}}</td>
              <td>{{tripSummary?.TRIPS}}</td>
              <td *ngFor="let vehicleType of tripSummary?.vehicleTypes">{{vehicleType.count}}</td>
              <td>{{tripSummary?.SECURITY ? tripSummary?.SECURITY : 0}}</td>
              <td>{{tripSummary?.SHIFT ? tripSummary?.SHIFT : 0}}</td>
              <td>{{tripSummary?.FEMALE ? tripSummary?.FEMALE : 0}}</td>
              <td>{{tripSummary?.OCCUPANCY ? tripSummary?.OCCUPANCY : 0}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 d-flex justify-content-start">
          <div class="col-sm-4">
            <input type="text" class="form-control icon s-input b-0"
              placeholder="Search by Employee Id/Name/Zone/Vehicle Type/Address" [(ngModel)]="searchTrip">
          </div>

          <div class="col-sm-8">
            <div class="button-items" fxLayoutAlign="end">
              <label class="me-2" style="position: relative; top: 8px;">Address</label>
              <ui-switch class="me-3" style="position: relative; top: 8px;" defaultBoColor="#dfdfdf"
                (change)="showOrHideAddress($event)" color="#34c38f" [checked]="(areaLandmark == true) ? true : false">
              </ui-switch>
              <label class="me-5" style="position: relative; top: 8px;">A/L</label>

              <label class="me-2" style="position: relative; top: 8px;">Show Contact</label>
              <ui-switch class="me-2" style="position: relative; top: 7px;" defaultBoColor="#dfdfdf"
                [checked]="(showContact == true) ? true : false" (change)="showOrHideContact($event)" color="#34c38f">
              </ui-switch>

              <div style="margin-top: -20px;">
                <div class="dropdown d-inline-block" ngbDropdown>
                  <button type="button" class="btn header-item border-none" id="page-header-user-dropdown"
                    ngbTooltip="Color Info" style="border: none;" ngbDropdownToggle>
                    <img src="assets/images/infor.svg">
                  </button>

                  <div class="dropdown-menu dropdown-menu-end" style="width: 400%;" ngbDropdownMenu>
                    <a class="dropdown-item d-block" href="javascript: void(0);">
                      <div class="cc1 me-3"></div>Normal
                    </a>
                    <a class="dropdown-item d-block" href="javascript: void(0);">
                      <div class="cc2 me-3"></div>Expectant Mother
                    </a>
                    <a class="dropdown-item d-block" href="javascript: void(0);">
                      <div class="cc6 me-3"></div>Hearing Impaired
                    </a>
                    <a class="dropdown-item d-block" href="javascript: void(0);">
                      <div class="cc4 me-3"></div>Additional Support
                    </a>
                    <a class="dropdown-item d-block" href="javascript: void(0);">
                      <div class="cc5 me-3"></div>PWD
                    </a>
                    <a class="dropdown-item d-block" href="javascript: void(0);">
                      <div class="cc3 me-3"></div>Out Of State Boundary
                    </a>
                    <a class="dropdown-item d-block" href="javascript: void(0);">
                      <div class="cc7 me-3"></div>Modified
                    </a>
                    <a class="dropdown-item d-block" href="javascript: void(0);">
                      <div class="cc8 me-3"></div>Vendor PushBack
                    </a>
                    <a class="dropdown-item d-block" href="javascript: void(0);">
                      <div class="cc9 me-3"></div>Other
                    </a>
                  </div>
                </div>

                <button *ngIf="tempVehicle" [disabled]="!editFlag" class="btn bg-white" style="border: none;"
                  (click)="AddTempVeh()" ngbTooltip="Add Ad-Hoc Vehicle">
                  <img src="assets/images/carplus.svg">
                </button>

                <button (click)="downloadAL()" style="border: none;" class="btn bg-white" ngbTooltip="Download All">
                  <img src="assets/images/download.svg">
                </button>

                <button class="btn bg-white" style="border: none;" (click)="showExceptionEmployees()"
                  ngbTooltip="Exception Employees">
                  <img src="assets/images/document.svg">
                </button>

                <button [disabled]="!editFlag" class="btn bg-white" style="border: none;" (click)="allocateVendor()"
                  ngbTooltip="Vendor Allocation">
                  <img src="assets/images/merge.svg">
                </button>

                <button [disabled]="!editFlag" class="btn bg-white" style="border: none;" (click)="modifyTrip()"
                  ngbTooltip="Modify Trip">
                  <img src="assets/images/modify.svg">
                </button>

                <button [disabled]="!editFlag" class="btn bg-white" style="border: none;" (click)="deleteTrip()"
                  ngbTooltip="Delete Trip">
                  <img src="assets/images/trash.svg">
                </button>
                <button ngbTooltip="Assign Vehicle" [disabled]="!editFlag" class="btn bg-white" *ngIf="adminTrackTrips"
                  (click)="vehicleAllocation()" style="border: none;"><span class="material-symbols-outlined"
                    style="color: #A1ABBC; font-size: 20px; font-weight: 400; position: relative; top: 4px;">
                    swap_driving_apps
                  </span></button>

                <button [disabled]="!editFlag" class="btn bg-white" style="border: none;" (click)="tripMerge()"
                  ngbTooltip="Trip Merge">
                  <img src="assets/images/merge_grey_icon.svg">
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-accordion displayMode="flat" multi class="custom-accordian">
  <section matSort (matSortChange)="sortData($event)" class="mat-header-row bg-white" style="padding: 5px 12px;">
    <span class="cell ms-2" mat-sort-header="tripCode">Trip Code</span>
    <span class="cell" mat-sort-header="vehicleNo">Vehicle No</span>
    <span class="cell" mat-sort-header="vendor">Vendor</span>
    <span class="cell" mat-sort-header="driver">Driver Name</span>
    <span class="cell" mat-sort-header="driver">Driver Number</span>
    <span class="cell" mat-sort-header="vehicleType">Vehicle Type</span>
    <span class="cell" mat-sort-header="zoneName" *ngIf="routingType == 'ALGORITHM'">Zone Name</span>
    <span class="cell" mat-sort-header="routeName" *ngIf="routingType == 'FIXED'">Route Name</span>
    <span class="cell" mat-sort-header="area">Area</span>
    <span class="cell" mat-sort-header="escort">Escort</span>
    <span class="cell" mat-sort-header="distance">Planned Kms</span>
    <span class="cell" mat-sort-header="distanceCovered">Actual Kms</span>
    <span class="cell" mat-sort-header="seatsOccupiedPercent">Occupancy</span>
    <span class="font4 me-3">Audit</span>
    <span class="font4" style="width: 55px;">Select All<mat-checkbox class="checkBox-trip" [checked]="checkedFlag"
        (change)="checkAll($event)"></mat-checkbox></span>
    <span *ngIf="!allExpandState" (click)="allExpandState=true">
      <mat-icon matTooltip="Expand all" matTooltipPosition="below">expand_more</mat-icon>
    </span>
    <span *ngIf="allExpandState" (click)="allExpandState=false">
      <mat-icon matTooltip="Collapse all" matTooltipPosition="below">expand_less</mat-icon>
    </span>
  </section>

  <div class="trip-expansion-container">
    <mat-expansion-panel #mep="matExpansionPanel" [disabled]="true" [expanded]="allExpandState"
      *ngFor="let item of displayedRows$ | tripSearchFilter : searchTrip">
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <span class="mat-cell">
          <span class="badge font" [style.background-color]="modifiedBgColor(item)" [style.color]="modifiedColor(item)"
            matTooltip={{item?.mtripCode}} (click)=mapview(item.tripid)>{{item?.tripCode}}
          </span>
        </span>
        <span class="mat-cell" matTooltip={{item?.vehicleNo}}>{{item?.vehicleNo}}</span>
        <span class="mat-cell" matTooltip={{item?.Vendor}}>{{item?.Vendor}}</span>
        <span class="mat-cell" matTooltip={{item?.Driver}}>{{item?.Driver}}</span>
        <span class="mat-cell" matTooltip={{item?.Number}}>{{item?.Number}}</span>
        <span class="mat-cell" matTooltip={{item?.vehicleType}}>{{item?.vehicleType}}</span>
        <span class="mat-cell" [matTooltip]="[item?.zone.length >= 10 ? item?.zone : '']" matTooltipPosition="above"
          *ngIf="routingType == 'ALGORITHM' && item?.masterRouteName == null">{{item?.zone}}</span>
        <span class="mat-cell" [matTooltip]="[item?.routeName.length >=10 ? item?.routeName : '']"
          matTooltipPosition="above"
          *ngIf="routingType == 'FIXED' && item?.masterRouteName == null">{{item?.routeName}}</span>
        <span class="mat-cell" [matTooltip]="[item?.masterRouteName.length >=10 ? item?.masterRouteName : '']"
          matTooltipPosition="above" *ngIf="item?.masterRouteName != null">(<span
            matTooltip="Master route">M</span>)-{{item?.masterRouteName}}</span>
        <span class="mat-cell" [matTooltip]="[item?.pickupDropPoint.length >=10 ? item?.pickupDropPoint : '']"
          matTooltipPosition="above">{{item?.pickupDropPoint}}</span>
        <span class="mat-cell" matTooltip={{item?.escort}}>{{item?.escort}}</span>
        <span class="mat-cell">{{item?.distance | number :'1.2-2'}} Km</span>
        <span class="mat-cell">{{item?.distanceCovered | number :'1.2-2'}} Km</span>
        <span class="mat-cell">{{item?.seatsOccupied}}/{{item?.seatCapacity}} = {{item?.seatsOccupiedPercent | number
          :'1.0-0'}}%</span>
        <span><i class="mdi mdi-circle font-size-10 me-3"
            [style.color]="percentage4BgColor(item?.seatsOccupiedPercent)"></i></span>
        <span class="me-3" title="Audit" (click)="getAudit(item)"><img src="assets/images/icons/audit.svg"></span>
        <mat-checkbox class="checkBox-trip" [checked]="checkedFlag"
          (change)="checkTrip($event,item.tripid)"></mat-checkbox>
        <span (click)="mep.expanded = !mep.expanded">
          <mat-icon matTooltip="Expand" matTooltipPosition="below" *ngIf="!mep.expanded">expand_more</mat-icon>
          <mat-icon matTooltip="Collapse" matTooltipPosition="below" *ngIf="mep.expanded">expand_less</mat-icon>
        </span>
      </mat-expansion-panel-header>

      <section class="mat-header-row header" style="background-color: #FBFCFF;">
        <span class="font4 ms-1">Date <span class="font5 ms-3"> {{item?.date}}</span></span>
        <span class="font4">Escort Name <span class="font5 ms-3">{{item?.escortname}}</span></span>
        <span class="font4">Total Duration <span class="font5 ms-3"
            *ngIf="item?.shift.slice(6,9) =='OUT'">{{item?.triptraveltime}} min</span></span>
        <span [ngClass]="{'disable': editFlag == false}">
          <input class="input-field me-3" placeholder="Travel Time(min)"
            *ngIf="item?.shift.slice(6,8) =='IN' && travelTimeFlag" value="{{item?.triptraveltime}}" type="text"
            maxlength="4" (keypress)="discard_special_char_And_String($event)" (input)="changeText($event, item)">
          <span *ngIf="item.logType =='IN'" (click)="updateTravelTime(item, getIndex(item))">
            <img src="assets/images/save.svg" class="cursor" height="15" matTooltip="Update">
          </span>
        </span>
        <!-- <span class="font4">Shift <span class="font5 ms-3"> {{item?.shift}}</span></span>
        <span class="font4">Avg Dept Time <span class="font5 ms-3"> {{item?.tripDate}}</span></span> -->
        <span class="font4">Start time- <span class="font5 ms-3"> {{item?.tatd}}</span></span>
        <span class="font4">Stop Time- <span class="font5 ms-3"> {{item?.stopTime}}</span></span>
      </section>

      <div class="card">
        <div class="card-body" style="box-shadow: 3px 3px 5px 2px #D9D9D9;">
          <table class="table table-panel">
            <thead>
              <th>Emp Id</th>
              <th>Name</th>
              <th>Gender</th>
              <th *ngIf="maskingEnabled && roleName != 'Vendor' && address">Address</th>
              <th *ngIf="!maskingEnabled && address">Address</th>
              <th *ngIf="areaLandmark">Area</th>
              <th *ngIf="areaLandmark" title="Landmark">Landmark</th>
              <th *ngIf="showContact">Contact</th>
              <th *ngIf="!showContact">Contact</th>
              <th>Distance</th>
              <th title="Drop Time/Pick Up">Drop Time/Pick Up</th>
              <th title="Travel Status" scope="col" colspan="2">Travel Status</th>
              <th>Check IN</th>
              <th>Check OUT</th>
              <th>SMS</th>
            </thead>

            <tbody>
              <tr *ngFor=" let emp of item.tripemp">
                <td>
                  <span class="badge bfont"
                    [ngStyle]="{'color': 'rgba(0, 0, 0, 0.8)', 'background': emp.userColor, 'opacity':'0.7', 'border': '1px solid' + emp.userColor}">
                    {{emp.employeeId}}
                  </span>
                </td>
                <td title={{emp.employeeName}}>{{emp.employeeName}}</td>
                <td>{{emp.gender}}</td>
                <td *ngIf="!address && roleName != 'Vendor' && address">{{emp.address.substring(0, 5)}}*********</td>
                <td *ngIf="address && roleName != 'Vendor' && address" title={{emp.address}}
                  style="white-space: initial;">{{emp.address}}</td>
                <td *ngIf="!maskingEnabled && roleName == 'Vendor' && address" title={{emp.address}}
                  style="white-space: initial;">{{emp.address}}</td>
                <td *ngIf="areaLandmark" title={{emp.area}}>{{emp.area}}</td>
                <td *ngIf="areaLandmark" title={{emp.landMark}} style="white-space: initial;">{{emp.landMark}}</td>
                <td *ngIf="!showContact">{{emp.contact.substring(0, 1)}}*******{{emp.contact.substring(8, 10)}}</td>
                <td *ngIf="showContact" title={{emp.contact}}>{{emp.contact}}</td>
                <td>{{emp?.distance | number :'1.2-2'}} Km</td>
                <td [ngClass]="{'disable': editFlag == false}">
                  <input *ngIf="item.logType =='IN'" class="input-field me-1" placeholder="Time* (Eg: 00:00)"
                    (keypress)="omit_special_char_And_String1($event)" autocomplete="off" maxlength="5"
                    value="{{emp.time ? emp.time.slice(0,5) : ''}}" (change)="onTimeValidate($event.target.value, emp)">
                  <span *ngIf="item.logType =='IN'" (click)="updateEmployeeTravelTime(emp)">
                    <img src="assets/images/save.svg" class="cursor" height="15" matTooltip="Update">
                  </span>
                </td>
                <td *ngIf="emp.showstatus=='Cancel'" [ngStyle]="{'color':'red'}">Cancelled</td>
                <td *ngIf="emp.showstatus!='Cancel'" title={{emp.showstatus}}>{{emp.showstatus}}</td>
                <td *ngIf="emp.showstatus!='Cancel'">
                  <span [ngClass]="{'disable': editFlag == false}" (click)="onShowStatusEdit(emp,item)">
                    <img src="assets/images/edit.svg" class="cursor" height="18">
                  </span>
                </td>
                <td>{{emp.checkInTime}}</td>
                <td>{{emp.checkOutTime}}</td>
                <td *ngIf="emp.smsStatus == 'DELIVRD'">
                  <img src="assets/images/tick.svg" title="DELIVERED">
                </td>
                <td *ngIf="emp.smsStatus != 'DELIVRD'">
                  <img src="assets/images/close.svg" [title]="emp.smsStatus == 'NA'?'NOT DELIVERED' : emp.smsStatus">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</mat-accordion>