<div fxLayout="row" style="display:flex; align-items: center; gap: 15px;">
    <h4 style="font-size: 17px; font-family: 'poppins'; font-weight: 500; position: relative; left: 20px; top: 5px;"
        fxFlex>Trip View</h4>
    <button class="btn red-btn-solid" *ngIf="searchButtonFlag" (click)="stopTrip()">
        Stop Trip
    </button>
    <button class="btn grey-btn-solid" *ngIf="modifyshow && modifyButtonFlag" (click)="modify()">
        Modify
    </button>
    <b style="font-size: 15px; font-weight: 500; font-family: 'poppins' !important;">Trip Code :
    </b>{{dialogData.data.tripCode}}
    <button mat-icon-button (click)="onClickCancel()">
        <mat-icon>clear</mat-icon>
    </button>
</div>
<div class="hr-line"></div>
<div fxLayout="row">
    <mat-card class="emp-card">
        <mat-card-content>
            <div class="table-responsive">
                <table fxFlex class="table table-striped mb-0">
                    <thead>
                        <tr>
                            <th style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;"> SlNo
                            </th>
                            <th style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;">
                                EmployeeId </th>
                            <th style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;">
                                EmployeeName </th>
                            <th style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;"> Location
                            </th>
                            <th
                                style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important; white-space: nowrap;">
                                Zone Name </th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let user of dialogData1">
                        <tr *ngFor="let user1 of user.data.tripUsers">
                            <td style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">
                                {{user1.routedOrder}} </td>
                            <td style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">
                                {{user1.tripUser.userInfoDetails.employeeId}} </td>
                            <td style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">
                                {{user1.tripUser.userInfoDetails.displayName}} </td>
                            <td style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">
                                {{user1.landmark.area.area}}, {{user1.landmark.landmark}} </td>
                            <td style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">
                                {{zoneName}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="map-card">
        <div id="map"></div>
    </mat-card>
</div>