import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { data } from 'jquery';

@Component({
  selector: 'app-show-unallocated-count-warning',
  templateUrl: './show-unallocated-count-warning.component.html',
  styleUrls: ['./show-unallocated-count-warning.component.scss']
})
export class ShowUnallocatedCountWarningComponent {

  constructor(public dialogRef: MatDialogRef<ShowUnallocatedCountWarningComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }
}
