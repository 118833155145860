import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { formatDate, Location } from "@angular/common";
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ModifyConfirmationComponent } from './modify-confirmation/modify-confirmation.component';
import "leaflet-routing-machine";
import * as GeoSearch from 'leaflet-geosearch';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { Constants } from 'src/app/core/constants/constants';
import { USE_DEFAULT_LANG } from '@ngx-translate/core';
declare let google: any;
declare let L;

export class tripModel {
  id: number;
  tripCode: string;
  vehicleType: string;
  vehicleTypeId: number;
  vehicleCap: number;
  escortStatus: boolean;
  tripDetails: any;
  usersList: usersListModel[];
  deviceTripStatus: string;
}

export class usersListModel {
  userId: number;
  userName: string;
  address: string;
  personalNumber: string;
  bookingid: number;
  routedOrder: number;
  showStatus: string;
}

@Component({
  selector: 'app-trip-modify',
  templateUrl: './trip-modify.component.html',
  styleUrls: ['./trip-modify.component.scss']
})
export class TripModifyComponent implements AfterViewInit, OnInit {
  searchSubject$ = new Subject<string>();
  searchSubjectTrip$ = new Subject<string>();
  tempSearchKey: any = '';
  autoSeqFlag: boolean = false;
  _mIsLoading = true;
  isOsm = false;
  isExceptionDragged: boolean;
  masterFlag: boolean;
  osmTripUsers = [];
  exceptionUsersList: any = [];
  exceptionUsersListDD: any[];
  _tripuserModel: usersListModel = new usersListModel();
  _tripUserModelList: usersListModel[] = [];
  toDropTrip: tripModel = new tripModel();
  _tripModel: tripModel = new tripModel();
  tripsList?: tripModel[] = [];
  tripempmarkers: any = [];
  exceptionEmps: any[] = [];
  vehicleList: any = [];
  tripdetails: any = [];
  tripids: any = [];
  tripUsersList: any[] = [];
  markers: any = [];
  polyline: any = [];
  siteid: number;
  shiftid: number;
  date: any;
  lat = 13.0196;
  lng = 77.5968;
  map: any;
  xUsers: any;
  siteData: any;
  sitemarker: any;
  toDropException: any;
  _tripIndex: number;
  _userIndex: number;
  addingNumber = -100;
  searchUsers: any;
  added: any;
  userSearchkey: any;
  masterRouteName: any;
  colours = [
    "#4B0082",
    "#228B22",
    "#8B0000",
    "#7FFFD4",
    "#000000",
    "#0000FF",
    "#FF8C00",
    "#2F4F4F"
  ];
  activeTabId = 1;
  panelBody = "300px";
  height = "400px";
  title: any = "Show Map";
  icon: any = "assets/images/red-eye.svg";
  removeUser: any = '';
  selectedUser: any;
  tempData: any = [];
  tempDataSearch: any = [];
  dataFlag: boolean = false;
  userSelected: any;
  tripDataTemp: any = [];
  dropMsg: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private readonly apiService: ApiService,
    private serviceSharedData: ServiceSharedData,
    private serviceLocalStorage: ServiceLocalStorage,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private cd: ChangeDetectorRef,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private dialog: MatDialog,
  ) {
    iconRegistry.addSvgIcon('stop', sanitizer.bypassSecurityTrustResourceUrl('assets/images/remove-user.svg'));
    this.searchSubject$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchKey => {
      this.tempSearchKey = searchKey;
      if (searchKey.trim().length !== 0) {
        this.getAllUsers(searchKey);
      } else if (searchKey.trim().length === 0) {
        this.exceptionUsersList = [];
      }
    });

    this.searchSubjectTrip$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchKey => {
      this.tempSearchKey = searchKey;
      this.onSearchKey(searchKey);
    });
  }


  ngOnInit() {
    this.route.params.subscribe(params => {
      this.siteid = params["siteId"];
      this.shiftid = params["shiftId"];
      this.date = params["date"];
    });
    this.tripids = this.serviceSharedData.getData("trips");
    this.tripUsersList = this.serviceSharedData.getData("tripUsersM");
    this._mIsLoading = false;
    this.height = "400px";
    this.panelBody = "300px";
    this.title = "Hide Map";
    this.icon = "assets/images/hide.svg";
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.tripids) this.onClickBack();
      if (this.serviceLocalStorage.getMapType() === Constants.OSRM) {
        this.isOsm = true;
        this.loadOsmap();
      } else {
        this.loadgmap();
      }
      if (this.tripids != null && this.tripids.length > 0) {
        this.getSite();
        this.getTripDetails();
      }
    }, 0);
  }

  onClickBack() {
    this.location.back();
  }

  getAllUsers(key) {
    this._mIsLoading = true;
    this.exceptionUsersList = [];
    this.exceptionUsersListDD = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.getExceptionAllSubscribedUser + key, success => {
      this.cd.markForCheck();
      if (success.status === 200) {
        this._mIsLoading = false;
        this.exceptionUsersList = success.body;
        success.body.forEach(user => {
          this.exceptionUsersListDD.push({
            data: user,
            name: user.userInfoDetails.displayName
          });
        });
      } else {
        this._mIsLoading = false;
        this.exceptionUsersList = [];
        this.exceptionUsersListDD = [];
        this.notifyService.showInfo(" Exception Employee is not present ", null);
      }
    }, error => {
      this._mIsLoading = false;
    });
  }

  getTripDetails() {
    let tripsIds = this.tripids.map(ele => {
      return 'tripids=' + ele;
    }).join('&');
    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.gettripsbytripids1 + tripsIds, '', success => {
      if (success.status == 200) {
        this.tripdetails = success.body[0];
        success.body[0].forEach((trip, tripIndex) => {
          this._tripModel = new tripModel();
          this._tripModel.id = trip.tripDetails.tripId;
          this._tripModel.tripCode = trip.tripDetails.tripCode == null ? "NA" : trip.tripDetails.tripCode.slice(0, 4) + trip.tripDetails.tripCode.slice(-4);
          this._tripModel.vehicleTypeId = trip.vehicleType.id;
          this._tripModel.vehicleType = trip.vehicleType.vehicleType;
          this._tripModel.escortStatus = trip.tripDetails.escortStatus;
          this._tripModel.vehicleCap = trip.vehicleType.seatCapacity;
          this._tripModel.tripDetails = trip.tripDetails;
          this._tripModel.deviceTripStatus = trip.DeviceTripStatus;
          this._tripUserModelList = [];
          let order = 1;
          trip.tripDetails.tripUsers.forEach(user => {

            user.currentStatus = trip.tripDetails.tripId;
            user.tripCode = trip.tripDetails.tripCode == null ? "NA" : trip.tripDetails.tripCode.slice(0, 4) + trip.tripDetails.tripCode.slice(-4);

            this._tripuserModel = new usersListModel();
            this._tripuserModel.userId = user.tripUser.id;
            this._tripuserModel.userName = user.tripUser.userInfoDetails.displayName;
            this._tripuserModel.routedOrder = order;
            this._tripuserModel.personalNumber = user.tripUser.userInfoDetails.employeeId;
            this._tripuserModel.address = user.landmark.area.area;
            this._tripUserModelList.push(this._tripuserModel);
            order++;
            this._tripuserModel.showStatus = user.showStatus || 'N/A';
          });
          this._tripModel.usersList = this._tripUserModelList;
          this.tripsList.push(this._tripModel);
          this.tripDataTemp.push(this._tripModel);
          this.tempData.push(this._tripuserModel);
          this.tempDataSearch.push(this._tripuserModel);
          this.cd.markForCheck();
        });
        this.ngxLoader.stop();
        this.showOnMap();
      } else {
        this.ngxLoader.stop();
        this.notifyService.showInfo("No Trips Found", null);
      }
      this.exceptionEmps = success.body[1];
    }, error => {
      this.ngxLoader.stop();
      this.notifyService.showInfo("No Trips Found", null);
    });
  }

  removeExceptionUser(i) {
    this.exceptionEmps.splice(i, 1);
  }

  loadOsmap() {
    this.map = new L.map("map", { fullscreenControl: true, dragging: true }).setView([this.lat, this.lng], 13);
    L.tileLayer(Constants.OSM_TILE, { attribution: Constants.OSM_CONTRIBUTORS }).addTo(this.map);
    this.map.addControl(new L.Control.Search(Constants.OSM_NOMINATIM_Obj));
    // const search = GeoSearch.GeoSearchControl({
    //   provider: new GeoSearch.OpenStreetMapProvider(),
    // });
    // this.map.addControl(search);
  }

  loadgmap() {
    let mapCenter = { lat: this.lat, lng: this.lng };
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: mapCenter,
      zoom: 12,
      draggable: true,
      zoomControl: true
    });
  }

  onItemSelect(e) {
    var i = this.exceptionEmps.length;
    while (i--) {
      if (this.exceptionEmps[i].userAdded === true) {
        this.exceptionEmps.splice(i, 1);
      }
    }
    this.xUsers.forEach((emps, i) => {
      let exceptionUser: any = {};
      exceptionUser.tripUser = emps.data;
      exceptionUser.landmark = emps.data.userInfoDetails.pickUpDrop;
      exceptionUser.userAdded = true;
      this.exceptionEmps.push(exceptionUser);
    });
  }

  searchUserSubmit(e) {
    this.cd.detach();
    let triparr = [];
    let flag = true;
    let tripUser = this.tripUsersList.find(u => u.id == e.id);
    // this.tripsList.forEach(trip => {
    //   let tripcode = trip.tripCode;
    //   trip.usersList.forEach(tuser => {
    //     triparr.push({ id: tuser.userId, tripCode: tripcode });
    //   });
    // });
    // triparr.forEach(trip => {
    //   if (trip.id == e.id) {
    //     flag = false;
    //     this.notifyService.showInfo(e.userInfoDetails.displayName + " already added in the " + trip.tripCode, null);
    //   }
    // });
    if (tripUser) {
      flag = false;
      this.notifyService.showInfo(e.userInfoDetails.displayName + " already added in the " + tripUser.tripCode, null);
    }
    if (flag) {
      if (this.exceptionEmps.length > 0) {
        this.exceptionEmps.forEach((emps, i) => {
          if ((this.exceptionEmps[i].userAdded === true && emps.tripUser.userInfoDetails.employeeId) == e.userInfoDetails.employeeId) {
            this.exceptionEmps.splice(i, 1);
            this.notifyService.showInfo(e.userInfoDetails.displayName + " already added in the list!", null);
          }
        });
      }
      let exceptionUser: any = {};
      exceptionUser.tripUser = e;
      exceptionUser.landmark = e.userInfoDetails.pickUpDrop;
      exceptionUser.userAdded = true;
      this.exceptionEmps.push(exceptionUser);
      this.cd.reattach();
      this.exceptionUsersList = [];
    } else {
      this.cd.reattach();
      this.exceptionUsersList = [];
    }
  }

  showOnMap() {
    let r = 0.05;
    const waypts = [];
    this.osmTripUsers = [];
    this.tripsList.forEach((element, t) => {
      if (element.tripDetails.tripUsers.length > 0) {
        let startnodelatlng = {
          lat: element.tripDetails.site.landmark.latitude,
          lng: element.tripDetails.site.landmark.longitude
        };
        let endnodelatlng = {
          lat: element.tripDetails.site.landmark.latitude,
          lng: element.tripDetails.site.landmark.longitude
        };
        if (element.tripDetails.shift) {
          if (element.tripDetails.shift.logType == "IN") {
            startnodelatlng = {
              lat: element.tripDetails.tripUsers[0] ? element.tripDetails.tripUsers[0].landmark.latitude : element.tripDetails.site.landmark.latitude,
              lng: element.tripDetails.tripUsers[0] ? element.tripDetails.tripUsers[0].landmark.longitude : element.tripDetails.site.landmark.longitude
            };
          } else {
            endnodelatlng = {
              lat: element.tripDetails.tripUsers[element.tripDetails.tripUsers.length - 1].landmark.latitude,
              lng: element.tripDetails.tripUsers[element.tripDetails.tripUsers.length - 1].landmark.longitude
            };
          }
        }
        let markerlist = [];
        element.tripDetails.tripUsers.forEach((tripuser, tIndex) => {
          waypts.push({
            location: {
              lat: tripuser.landmark.latitude,
              lng: tripuser.landmark.longitude
            },
            stopover: true
          });
          let icon = "./assets/images/livetracking/maleloc.svg";
          if (tripuser.tripUser.userInfoDetails.gender == "Female") {
            icon = "./assets/images/livetracking/femaleloc.svg";
          }
          if (!this.isOsm) {
            var marker = new google.maps.Marker({
              position: {
                lat: tripuser.landmark.latitude,
                lng: tripuser.landmark.longitude
              },
              icon: { url: icon, labelOrigin: new google.maps.Point(18, -8) },
              draggable: false,
              map: this.map,
              title: tripuser.tripUser.userInfoDetails.displayName + " - " + tripuser.routedOrder,
              label: {
                text: "P" + (tIndex + 1),
                color: "black",
                fontWeight: "bold",
                fontSize: "16px"
              }
            });
          }
          markerlist.push(marker);
          this.markers.push(marker);
        });
        this.osmTripUsers.push(element.tripDetails);
        this.tripempmarkers.push({
          tripid: element.tripDetails.tripId,
          markers: markerlist
        });
        if (this.sitemarker == undefined && !this.isOsm) {
          this.markSite(element.tripDetails.site);
        }
        if (!this.isOsm) {
          let request = {
            origin: startnodelatlng,
            destination: endnodelatlng,
            waypoints: waypts,
            optimizeWaypoints: false,
            avoidHighways: false,
            travelMode: google.maps.DirectionsTravelMode.WALKING
          };
          let directionsService = new google.maps.DirectionsService();
          let directionsDisplay = new google.maps.DirectionsRenderer({
            polylineOptions: {
              strokeColor: this.colours[t],
              strokeWeight: 3,
              strokeOpacity: 0.8
            }
          });
          r = r + 0.05;
          directionsDisplay.setMap(this.map);
          directionsDisplay.setOptions({ suppressMarkers: true });
          directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
              directionsDisplay.setDirections(response);
            }
          });
        }
      }
    });
    if (this.isOsm) {
      this.getOsmUsersRoute();
    } else {
      this.ngxLoader.stop();
    }
  }

  markSite(site) {
    let path = "./assets/images/company.png";
    if (!this.isOsm) {
      let sitecordinates = new google.maps.LatLng(
        site.landmark.latitude,
        site.landmark.longitude
      );
      this.sitemarker = new google.maps.Marker({
        position: sitecordinates,
        icon: {
          url: path,
          scaledSize: new google.maps.Size(40, 40)
        },
        map: this.map,
        title: site.siteName
      });
    } else {
      this.sitemarker = new L.marker(
        [site.landmark.latitude, site.landmark.longitude],
        {
          icon: L.icon({
            iconUrl: path,
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          }),
          title: site.siteName
        }
      );
    }
  }

  getOsmUsersRoute() {
    const trips = [];
    let op = 0.15;
    // this.tripsList.forEach(trip => {
    //   if (this.removeUser !== trip.id) {
    //     if (trip.tripDetails != null && trip.tripDetails.tripUsers != null && trip.tripDetails.tripUsers.length > 0) {
    //       trips.push(trip.tripDetails);
    //     }
    //   }
    // });
    this.tripsList.forEach(trip => {
      if (trip.tripDetails.tripUsers.length > 0) {
        trips.push(trip.tripDetails);
      }
    });
    let tripDetailList = this.tripsList.filter(trip => trip.tripDetails != null && trip.tripDetails.tripUsers != null && trip.tripDetails.tripUsers.length > 0);
    let k = true;
    this.apiService.doPostRequestWithResponse(ApiConstants.gettripmodifypreview + this._tripModel.tripDetails.shift.logType + '&siteid=' + this.siteid, trips, success => {
      success.body.trips.forEach((trips, t) => {
        let wps = [];
        trips.OSRM.waypoints.forEach(wp => {
          wps.push(L.latLng(wp.location[1], wp.location[0]));
        });
        // let t = this.removeUser ? tripDetailList.findIndex(ele => ele.id !== this.removeUser) : index;
        // tripDetailList.forEach(trip => {
        // if (this.removeUser !== trip.id) {
        if (this.removeUser) {
          let objIndex = this.tripsList.findIndex(ele => ele.id == this.removeUser);
          if (this.tripsList[objIndex].tripDetails.tripUsers.length > 0) {
            if (this.tripsList[t].tripDetails.tripUsers.length != 0 && k) {
              let r = L.Routing.control({
                serviceUrl: Constants.OSM_ROUTING_URL,
                showAlternatives: false,
                lineOptions: {
                  styles: [{
                    color: this.colours[t],
                    weight: 3,
                    opacity: 0.8
                  }]
                },
                fitSelectedRoutes: true,
                waypointMode: "connect",
                addWaypoints: false,
                draggableWaypoints: false,
                autoRoute: true,
                reverseWaypoints: false,
                show: true,
                routeWhileDragging: false,
                createMarker: (i, wp, nWps) => {
                  if (success.body.trips[t].LOGTYPE === "IN") {
                    if (i === this.tripsList[t].tripDetails.tripUsers.length) {
                      let m = L.marker(wp.latLng, {
                        icon: L.icon({
                          iconUrl: "./assets/images/company.png",
                          iconSize: [40, 40]
                        })
                      }).bindPopup(
                        this.tripsList[t].tripDetails.tripUsers[
                          this.tripsList[t].tripDetails.tripUsers.length - 1
                        ].landmark.landmark
                      );
                      return m;
                    }
                    if (i < this.tripsList[t].tripDetails.tripUsers.length) {
                      let m = L.marker(wp.latLng, {
                        icon: L.icon({
                          iconUrl: this.tripsList[t].tripDetails.tripUsers[i].tripUser.userInfoDetails.gender === "Female"
                            ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                          iconSize: [65, 70]
                        })
                      }).bindPopup('<tr><td>UserName : </td<td>' + this.tripsList[t].tripDetails.tripUsers[i].tripUser
                        .userInfoDetails.displayName + '</td><br> <td>AL : </td><td>' +
                        this.tripsList[t].tripDetails.tripUsers[i].landmark.area.area + ', ' +
                        this.tripsList[t].tripDetails.tripUsers[i].landmark.landmark + '</td></tr>').bindTooltip(i + 1 + "", {
                          permanent: true,
                          direction: "left",
                          offset: L.point(-10, 0)
                        })
                        .openTooltip();
                      return m;
                    }
                  } else {
                    if (i === 0) {
                      let m = L.marker(wp.latLng, {
                        icon: L.icon({
                          iconUrl: "./assets/images/company.png",
                          iconSize: [40, 40]
                        })
                      }).bindPopup(
                        this.tripsList[t].tripDetails.tripUsers[0].landmark.landmark
                      );
                      return m;
                    }
                    if (i - 1 <= this.tripsList[t].tripDetails.tripUsers.length) {
                      let m = L.marker(wp.latLng, {
                        icon: L.icon({
                          iconUrl: this.tripsList[t].tripDetails.tripUsers[i - 1].tripUser.userInfoDetails.gender === "Female"
                            ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                          iconSize: [65, 70]
                        })
                      }).bindPopup('<tr><td>UserName : </td<td>' + this.tripsList[t].tripDetails.tripUsers[i - 1].tripUser
                        .userInfoDetails.displayName + '</td><br> <td>AL : </td><td>' +
                        this.tripsList[t].tripDetails.tripUsers[i - 1].landmark.area.area + ', ' +
                        this.tripsList[t].tripDetails.tripUsers[i - 1].landmark.landmark + '</td></tr>').bindTooltip(i + "", {
                          permanent: true,
                          direction: "left",
                          offset: L.point(-10, 0)
                        }).openTooltip();
                      return m;
                    }
                  }
                  op = op + 0.15;
                }
              }).addTo(this.map);
              r.setWaypoints(wps);
              r.hide();
            } else {
              this.showOnPrevMap(tripDetailList);
            }
          } else {
            if (tripDetailList[t].tripDetails.tripUsers.length != 0 && k) {
              let r = L.Routing.control({
                serviceUrl: Constants.OSM_ROUTING_URL,
                showAlternatives: false,
                lineOptions: {
                  styles: [{
                    color: this.colours[t],
                    weight: 3,
                    opacity: 0.8
                  }]
                },
                fitSelectedRoutes: true,
                waypointMode: "connect",
                addWaypoints: false,
                draggableWaypoints: false,
                autoRoute: true,
                reverseWaypoints: false,
                show: true,
                routeWhileDragging: false,
                createMarker: (i, wp, nWps) => {
                  if (success.body.trips[t].LOGTYPE === "IN") {
                    if (i === tripDetailList[t].tripDetails.tripUsers.length) {
                      let m = L.marker(wp.latLng, {
                        icon: L.icon({
                          iconUrl: "./assets/images/company.png",
                          iconSize: [40, 40]
                        })
                      }).bindPopup(
                        tripDetailList[t].tripDetails.tripUsers[
                          tripDetailList[t].tripDetails.tripUsers.length - 1
                        ].landmark.landmark
                      );
                      return m;
                    }
                    if (i < tripDetailList[t].tripDetails.tripUsers.length) {
                      let m = L.marker(wp.latLng, {
                        icon: L.icon({
                          iconUrl: tripDetailList[t].tripDetails.tripUsers[i].tripUser.userInfoDetails.gender === "Female"
                            ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                          iconSize: [65, 70]
                        })
                      }).bindPopup('<tr><td>UserName : </td<td>' + tripDetailList[t].tripDetails.tripUsers[i].tripUser
                        .userInfoDetails.displayName + '</td><br> <td>AL : </td><td>' +
                        tripDetailList[t].tripDetails.tripUsers[i].landmark.area.area + ', ' +
                        tripDetailList[t].tripDetails.tripUsers[i].landmark.landmark + '</td></tr>').bindTooltip(i + 1 + "", {
                          permanent: true,
                          direction: "left",
                          offset: L.point(-10, 0)
                        })
                        .openTooltip();
                      return m;
                    }
                  } else {
                    if (i === 0) {
                      let m = L.marker(wp.latLng, {
                        icon: L.icon({
                          iconUrl: "./assets/images/company.png",
                          iconSize: [40, 40]
                        })
                      }).bindPopup(
                        tripDetailList[t].tripDetails.tripUsers[0].landmark.landmark
                      );
                      return m;
                    }
                    if (i - 1 <= tripDetailList[t].tripDetails.tripUsers.length) {
                      let m = L.marker(wp.latLng, {
                        icon: L.icon({
                          iconUrl: tripDetailList[t].tripDetails.tripUsers[i - 1].tripUser.userInfoDetails.gender === "Female"
                            ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                          iconSize: [65, 70]
                        })
                      }).bindPopup('<tr><td>UserName : </td<td>' + tripDetailList[t].tripDetails.tripUsers[i - 1].tripUser
                        .userInfoDetails.displayName + '</td><br> <td>AL : </td><td>' +
                        tripDetailList[t].tripDetails.tripUsers[i - 1].landmark.area.area + ', ' +
                        tripDetailList[t].tripDetails.tripUsers[i - 1].landmark.landmark + '</td></tr>').bindTooltip(i + "", {
                          permanent: true,
                          direction: "left",
                          offset: L.point(-10, 0)
                        }).openTooltip();
                      return m;
                    }
                  }
                  op = op + 0.15;
                }
              }).addTo(this.map);
              r.setWaypoints(wps);
              r.hide();
            } else {
              this.showOnPrevMap(tripDetailList);
            }
          }
        } else {
          if (this.tripsList[t].tripDetails.tripUsers.length != 0 && k) {
            let r = L.Routing.control({
              serviceUrl: Constants.OSM_ROUTING_URL,
              showAlternatives: false,
              lineOptions: {
                styles: [{
                  color: this.colours[t],
                  weight: 3,
                  opacity: 0.8
                }]
              },
              fitSelectedRoutes: true,
              waypointMode: "connect",
              addWaypoints: false,
              draggableWaypoints: false,
              autoRoute: true,
              reverseWaypoints: false,
              show: true,
              routeWhileDragging: false,
              createMarker: (i, wp, nWps) => {
                if (success.body.trips[t].LOGTYPE === "IN") {
                  if (i === this.tripsList[t].tripDetails.tripUsers.length) {
                    let m = L.marker(wp.latLng, {
                      icon: L.icon({
                        iconUrl: "./assets/images/company.png",
                        iconSize: [40, 40]
                      })
                    }).bindPopup(
                      this.tripsList[t].tripDetails.tripUsers[
                        this.tripsList[t].tripDetails.tripUsers.length - 1
                      ].landmark.landmark
                    );
                    return m;
                  }
                  if (i < this.tripsList[t].tripDetails.tripUsers.length) {
                    let m = L.marker(wp.latLng, {
                      icon: L.icon({
                        iconUrl: this.tripsList[t].tripDetails.tripUsers[i].tripUser.userInfoDetails.gender === "Female"
                          ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                        iconSize: [65, 70]
                      })
                    }).bindPopup('<tr><td>UserName : </td<td>' + this.tripsList[t].tripDetails.tripUsers[i].tripUser
                      .userInfoDetails.displayName + '</td><br> <td>AL : </td><td>' +
                      this.tripsList[t].tripDetails.tripUsers[i].landmark.area.area + ', ' +
                      this.tripsList[t].tripDetails.tripUsers[i].landmark.landmark + '</td></tr>').bindTooltip(i + 1 + "", {
                        permanent: true,
                        direction: "left",
                        offset: L.point(-10, 0)
                      })
                      .openTooltip();
                    return m;
                  }
                } else {
                  if (i === 0) {
                    let m = L.marker(wp.latLng, {
                      icon: L.icon({
                        iconUrl: "./assets/images/company.png",
                        iconSize: [40, 40]
                      })
                    }).bindPopup(
                      this.tripsList[t].tripDetails.tripUsers[0].landmark.landmark
                    );
                    return m;
                  }
                  if (i - 1 <= this.tripsList[t].tripDetails.tripUsers.length) {
                    let m = L.marker(wp.latLng, {
                      icon: L.icon({
                        iconUrl: this.tripsList[t].tripDetails.tripUsers[i - 1].tripUser.userInfoDetails.gender === "Female"
                          ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                        iconSize: [65, 70]
                      })
                    }).bindPopup('<tr><td>UserName : </td<td>' + this.tripsList[t].tripDetails.tripUsers[i - 1].tripUser
                      .userInfoDetails.displayName + '</td><br> <td>AL : </td><td>' +
                      this.tripsList[t].tripDetails.tripUsers[i - 1].landmark.area.area + ', ' +
                      this.tripsList[t].tripDetails.tripUsers[i - 1].landmark.landmark + '</td></tr>').bindTooltip(i + "", {
                        permanent: true,
                        direction: "left",
                        offset: L.point(-10, 0)
                      }).openTooltip();
                    return m;
                  }
                }
                op = op + 0.15;
              }
            }).addTo(this.map);
            r.setWaypoints(wps);
            r.hide();
          } else {
            this.showOnPrevMap(tripDetailList);
          }
        }
      });

    }, error => {
      console.log(error);
    }
    );
  }

  showOnPrevMap(tripDetailList) {
    let r = 0.05;
    const waypts = [];
    this.osmTripUsers = [];
    tripDetailList.forEach((element, t) => {
      if (element.tripDetails.tripUsers.length > 0) {
        let startnodelatlng = {
          lat: element.tripDetails.site.landmark.latitude,
          lng: element.tripDetails.site.landmark.longitude
        };
        let endnodelatlng = {
          lat: element.tripDetails.site.landmark.latitude,
          lng: element.tripDetails.site.landmark.longitude
        };
        if (element.tripDetails.shift) {
          if (element.tripDetails.shift.logType == "IN") {
            startnodelatlng = {
              lat: element.tripDetails.tripUsers[0] ? element.tripDetails.tripUsers[0].landmark.latitude : element.tripDetails.site.landmark.latitude,
              lng: element.tripDetails.tripUsers[0] ? element.tripDetails.tripUsers[0].landmark.longitude : element.tripDetails.site.landmark.longitude
            };
          } else {
            endnodelatlng = {
              lat: element.tripDetails.tripUsers[element.tripDetails.tripUsers.length - 1].landmark.latitude,
              lng: element.tripDetails.tripUsers[element.tripDetails.tripUsers.length - 1].landmark.longitude
            };
          }
        }
        let markerlist = [];
        element.tripDetails.tripUsers.forEach((tripuser, tIndex) => {
          waypts.push({
            location: {
              lat: tripuser.landmark.latitude,
              lng: tripuser.landmark.longitude
            },
            stopover: true
          });
          let icon = "./assets/images/livetracking/maleloc.svg";
          if (tripuser.tripUser.userInfoDetails.gender == "Female") {
            icon = "./assets/images/livetracking/femaleloc.svg";
          }
          if (!this.isOsm) {
            var marker = new google.maps.Marker({
              position: {
                lat: tripuser.landmark.latitude,
                lng: tripuser.landmark.longitude
              },
              icon: { url: icon, labelOrigin: new google.maps.Point(18, -8) },
              draggable: false,
              map: this.map,
              title: tripuser.tripUser.userInfoDetails.displayName + " - " + tripuser.routedOrder,
              label: {
                text: "P" + (tIndex + 1),
                color: "black",
                fontWeight: "bold",
                fontSize: "16px"
              }
            });
          }
          markerlist.push(marker);
          this.markers.push(marker);
        });
        this.osmTripUsers.push(element.tripDetails);
        this.tripempmarkers.push({
          tripid: element.tripDetails.tripId,
          markers: markerlist
        });
        if (this.sitemarker == undefined && !this.isOsm) {
          this.markSite(element.tripDetails.site);
        }
        if (!this.isOsm) {
          let request = {
            origin: startnodelatlng,
            destination: endnodelatlng,
            waypoints: waypts,
            optimizeWaypoints: false,
            avoidHighways: false,
            travelMode: google.maps.DirectionsTravelMode.WALKING
          };
          let directionsService = new google.maps.DirectionsService();
          let directionsDisplay = new google.maps.DirectionsRenderer({
            polylineOptions: {
              strokeColor: this.colours[t],
              strokeWeight: 3,
              strokeOpacity: 0.8
            }
          });
          r = r + 0.05;
          directionsDisplay.setMap(this.map);
          directionsDisplay.setOptions({ suppressMarkers: true });
          directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
              directionsDisplay.setDirections(response);
            }
          });
        }
      }
    });
    if (this.isOsm) {
      this.showExactRouteOnMp(tripDetailList);
    } else {
      this.ngxLoader.stop();
    }
  }

  showExactRouteOnMp(tripList) {
    let updatedColours = [...this.colours];
    let matchingIndex: any = [];
    this.tripsList.forEach((trip, index) => {
      const matchingTrip = tripList.find(t => t.id == trip.id);
      if (!matchingTrip) {
        matchingIndex.push(index);
      }
    });
    if (matchingIndex) {
      matchingIndex.sort((a, b) => b - a);
      matchingIndex.forEach(index => {
        if (index >= 0 && index < this.colours.length) {
          updatedColours.splice(index, 1);
        }
      });
    }
    const trips = [];
    const trips1: any = [];
    let op = 0.15;
    tripList.forEach(trip => {
      if (trip.tripDetails.tripUsers.length > 0) {
        trips.push(trip.tripDetails);
        trips1.push(trip);
      }
    });
    let tripDetailList = tripList.filter(trip => trip.tripDetails != null && trip.tripDetails.tripUsers != null && trip.tripDetails.tripUsers.length > 0);
    let k = true;
    this.apiService.doPostRequestWithResponse(ApiConstants.gettripmodifypreview + this._tripModel.tripDetails.shift.logType + '&siteid=' + this.siteid, trips, success => {
      success.body.trips.forEach((trips, t) => {
        let wps = [];
        trips.OSRM.waypoints.forEach(wp => {
          wps.push(L.latLng(wp.location[1], wp.location[0]));
        });
        if (tripDetailList[t].tripDetails.tripUsers.length != 0 && k) {
          let r = L.Routing.control({
            serviceUrl: Constants.OSM_ROUTING_URL,
            showAlternatives: false,
            lineOptions: {
              styles: [{
                color: updatedColours[t],
                weight: 3,
                opacity: 0.8
              }]
            },
            fitSelectedRoutes: true,
            waypointMode: "connect",
            addWaypoints: false,
            draggableWaypoints: false,
            autoRoute: true,
            reverseWaypoints: false,
            show: true,
            routeWhileDragging: false,
            createMarker: (i, wp, nWps) => {
              if (success.body.trips[t].LOGTYPE === "IN") {
                if (i === tripDetailList[t].tripDetails.tripUsers.length) {
                  let m = L.marker(wp.latLng, {
                    icon: L.icon({
                      iconUrl: "./assets/images/company.png",
                      iconSize: [40, 40]
                    })
                  }).bindPopup(
                    tripDetailList[t].tripDetails.tripUsers[
                      tripDetailList[t].tripDetails.tripUsers.length - 1
                    ].landmark.landmark
                  );
                  return m;
                }
                if (i < tripDetailList[t].tripDetails.tripUsers.length) {
                  let m = L.marker(wp.latLng, {
                    icon: L.icon({
                      iconUrl: tripDetailList[t].tripDetails.tripUsers[i].tripUser.userInfoDetails.gender === "Female"
                        ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                      iconSize: [65, 70]
                    })
                  }).bindPopup('<tr><td>UserName : </td<td>' + tripDetailList[t].tripDetails.tripUsers[i].tripUser
                    .userInfoDetails.displayName + '</td><br> <td>AL : </td><td>' +
                    tripDetailList[t].tripDetails.tripUsers[i].landmark.area.area + ', ' +
                    tripDetailList[t].tripDetails.tripUsers[i].landmark.landmark + '</td></tr>').bindTooltip(i + 1 + "", {
                      permanent: true,
                      direction: "left",
                      offset: L.point(-10, 0)
                    })
                    .openTooltip();
                  return m;
                }
              } else {
                if (i === 0) {
                  let m = L.marker(wp.latLng, {
                    icon: L.icon({
                      iconUrl: "./assets/images/company.png",
                      iconSize: [40, 40]
                    })
                  }).bindPopup(
                    tripDetailList[t].tripDetails.tripUsers[0].landmark.landmark
                  );
                  return m;
                }
                if (i - 1 <= tripDetailList[t].tripDetails.tripUsers.length) {
                  let m = L.marker(wp.latLng, {
                    icon: L.icon({
                      iconUrl: tripDetailList[t].tripDetails.tripUsers[i - 1].tripUser.userInfoDetails.gender === "Female"
                        ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                      iconSize: [65, 70]
                    })
                  }).bindPopup('<tr><td>UserName : </td<td>' + tripDetailList[t].tripDetails.tripUsers[i - 1].tripUser
                    .userInfoDetails.displayName + '</td><br> <td>AL : </td><td>' +
                    tripDetailList[t].tripDetails.tripUsers[i - 1].landmark.area.area + ', ' +
                    tripDetailList[t].tripDetails.tripUsers[i - 1].landmark.landmark + '</td></tr>').bindTooltip(i + "", {
                      permanent: true,
                      direction: "left",
                      offset: L.point(-10, 0)
                    }).openTooltip();
                  return m;
                }
              }
              op = op + 0.15;
            }
          }).addTo(this.map);
          r.setWaypoints(wps);
          r.hide();
        }
      });

    }, error => {
      console.log(error);
    }
    );
  }

  addTrip() {
    let tripmod: any = new tripModel();
    tripmod.tripDetails = { site: { landmark: "" } };
    tripmod.id = this.addingNumber;
    tripmod.tripCode = "ADD" + this.addingNumber;
    tripmod.vehicleType = this.vehicleList[0].vehicleType;
    tripmod.vehicleTypeId = this.vehicleList[0].id;
    tripmod.vehicleCap = this.vehicleList[0].seatCapacity;
    tripmod.tripDetails.site.landmark = this.siteData;
    tripmod.escortStatus = false;
    tripmod.tripDetails.tripUsers = [];
    tripmod.usersList = [];
    tripmod.deviceTripStatus = "not started";
    this.addingNumber++;
    this.tripsList.push(tripmod);
  }

  escortClick(trip) {
    if (trip.tripDetails.tripUsers.length == trip.vehicleCap) {
      this.notifyService.showWarning("Please remove any one employee to enable escort", null);
    }
  }

  onExceptionDrop(user) {
    this.cd.detach();
  }

  removeDumplicateValue(myArray, user) {
    var newArray = [];
    let flag = false;
    let tripCode = 0;
    myArray.forEach((value, key1) => {
      var exists = false;
      newArray.forEach((val2, key) => {
        if (value.tripUser.id == val2.tripUser.id) { exists = true; flag = true; tripCode = val2.tripCode; };
      });
      if (exists == false && value.tripUser.id != "") { newArray.push(value); }
    });
    if (flag) {
      this.notifyService.showInfo(user.tripUser.userInfoDetails.displayName + " is already present in the " + tripCode, null);
      this.exceptionEmps.splice(this._userIndex, 0, user);
    }
    return newArray;
  }

  onItemDrop(trip, user, tripIndex) {
    this.dropMsg = true;
    user = this.selectedUser !== undefined ? this.selectedUser : user;
    let escortCount = trip.escortStatus ? 1 : 0;
    let arr = [];
    this.tripsList.forEach((trips, t) => {
      arr = trips.tripDetails.tripUsers;
      trips.tripDetails.tripUsers = this.removeDumplicateValue(arr, user);
      if (trip.id == trips.id) {
        // this.ngxLoader.start();
        if (trip.tripDetails.tripUsers.length + escortCount > trip.vehicleCap) {
          this.dropMsg = true;
          setTimeout(() => {
            this.notifyService.showWarning("Reached maximum seat capacity!", null);
            let index = this.tripsList[t].tripDetails.tripUsers.indexOf(user);
            if (index > -1 && user.id === trips.tripDetails.tripUsers[index].id) {
              this.ngxLoader.stop();
              trips.tripDetails.tripUsers.splice(index, 1);
            }
          }, 600);
          if (!this.isExceptionDragged) {
            this.dropMsg = true;
            setTimeout(() => {
              this.ngxLoader.stop();
              this.tripsList[this._tripIndex].tripDetails.tripUsers.splice(
                this._userIndex,
                0,
                user
              );
              const uniqueUsersMap = new Map();
              this.tripsList[this._tripIndex].tripDetails.tripUsers.forEach(user => {
                uniqueUsersMap.set(user.id, user);
              });
              this.tripsList[this._tripIndex].tripDetails.tripUsers = Array.from(uniqueUsersMap.values());
            }, 100);
          } else {
            setTimeout(() => {
              this.ngxLoader.stop();
              this.dropMsg = true;
              let objIndex = this.tripsList[t].tripDetails.tripUsers.indexOf(user);
              if (objIndex > -1 && user.id === trips.tripDetails.tripUsers[objIndex].id) {
                trips.tripDetails.tripUsers.forEach(ele => {
                  if (ele.id == user.id) {
                    trips.tripDetails.tripUsers.splice(objIndex, 1);
                    this.exceptionEmps.splice(this._userIndex, 0, ele);
                  }
                });
              }
              // this.exceptionEmps.splice(this._userIndex, 0, user);
              const uniqueUsersMap = new Map();
              this.exceptionEmps.forEach(user => {
                uniqueUsersMap.set(user.id, user);
              });
              this.exceptionEmps = Array.from(uniqueUsersMap.values());
            }, 100);
          }
        }
        setTimeout(() => {
          this.ngxLoader.stop();
        }, 600);
      }
    });
    // this.cd.reattach();
  }


  dragEnded(trip, tIndex, uIndex) {
    this.cd.reattach();
  }

  dragStarted(trip, tripIndex, userIndex) {
    this.dropMsg = false;
    this.cd.detach();
    this.isExceptionDragged = false;
    this.toDropTrip = trip;
    this._tripIndex = tripIndex;
    this._userIndex = userIndex;
    let userData = trip.tripDetails.tripUsers[userIndex];

    if (trip && this.dropMsg == false) {
      let removeUser = false;
      setTimeout(() => {
        for (let i = 0; i < this.tripsList.length; i++) {
          let data = this.tripsList[i];
          let escortCount = data.escortStatus ? 1 : 0;
          if (data.tripDetails.tripUsers.length + escortCount > data.vehicleCap) {
            const userIndex = data.tripDetails.tripUsers.findIndex(user => user.id === userData.id);
            if (userIndex !== -1) {
              removeUser = true;
              data.tripDetails.tripUsers.splice(userIndex, 1);
              this.notifyService.showWarning("Reached maximum seat capacity!", null);
              break;
            }
          }
        }
        if (removeUser) {
          trip.tripDetails.tripUsers.splice(0, 0, userData);
          const uniqueUsersMap = new Map();
          trip.tripDetails.tripUsers.forEach(user => {
            uniqueUsersMap.set(user.id, user);
          });
          trip.tripDetails.tripUsers = Array.from(uniqueUsersMap.values());
        }
      }, 1000);
    }
  }

  exceptionDragStarted(user, index) {
    this.dropMsg = false;
    this.selectedUser = user;
    this.removeUser = '';
    this.isExceptionDragged = true;
    this.toDropException = user;
    this._userIndex = index;
    this.cd.reattach();

    setTimeout(() => {
      if (this.dropMsg == false) {
        this.tripsList.forEach((trip, i) => {
          trip.tripDetails.tripUsers.forEach((ele) => {
            if (ele.id === user.id) {
              let escortCount = trip.escortStatus ? 1 : 0;
              if (this.tripsList[i].tripDetails.tripUsers.length + escortCount > this.tripsList[i].vehicleCap) {
                setTimeout(() => {
                  // this.ngxLoader.start();
                  this.exceptionUserDrop(user);
                }, 600);
              }
            }
          });
        });
      }
    }, 600);
  }

  exceptionUserDrop(user) {
    this.ngxLoader.stop();
    this.tripsList.forEach((trip, t) => {
      trip.tripDetails.tripUsers.forEach((ele1) => {
        if (ele1.id === user.id) {
          let escortCount = trip.escortStatus ? 1 : 0;
          let findIndex = this.tripsList[t].tripDetails.tripUsers.indexOf(ele1);
          if (findIndex > -1 && (this.tripsList[t].tripDetails.tripUsers.length + escortCount > this.tripsList[t].vehicleCap)) {
            this.notifyService.showWarning("Reached maximum seat capacity!", null);
            this.tripsList[t].tripDetails.tripUsers.splice(findIndex, 1);
            this.exceptionEmps.splice(this._userIndex, 0, ele1);
            const uniqueUsersMap = new Map();
            this.exceptionEmps.forEach(user => {
              uniqueUsersMap.set(user.id, user);
            });
            this.exceptionEmps = Array.from(uniqueUsersMap.values());
          }
        }
      });
    });
  }

  dragEndTrips(event, trip) { }

  getSite() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getSitesById + this.siteid, success => {
      this.vehicleList = success.body.dataList.content[0].vehicleTypes;
      this.siteData = success.body.dataList.content[0].landmark;
    }, error => {
      this.notifyService.showWarning("Unable to fetch vehicles!", null);
    });
  }

  SelectedVehicle(vehicleid, trip) {
    this.tripsList.forEach(tt => {
      if (tt.tripCode == trip.tripCode) {
        this.vehicleList.forEach(veh => {
          if (veh.id == vehicleid) {
            let escortCount = 0;
            if (trip.escortStatus) {
              escortCount = 1;
            }
            if (trip.tripDetails.tripUsers.length <= veh.seatCapacity) {
              tt.vehicleCap = veh.seatCapacity;
              tt.vehicleType = veh.vehicleType;
              tt.vehicleTypeId = veh.id;
            } else {
              this.notifyService.showInfo("Seat capacity is less than employee occupied", null);
            }
          }
        });
      }
    });
  }

  escortChange(flag, trip) {
    this.tripsList.forEach(tt => {
      if (tt.tripCode == trip.tripCode) {
        tt.escortStatus = flag;
        tt.tripDetails.escortStatus = flag;
      }
    });
    this.updateEscortStatusByAdmin(trip.id, flag);
  }

  validateExceptionEmps() {
    if (this.exceptionEmps.length > 0) {
      if (confirm("Are you sure to save with exception employees? ")) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  modificationConfirmation() {
    let dialogRef = this.dialog.open(ModifyConfirmationComponent, {
      width: "600px",
      disableClose: true,
      data: { text: "Do you want this trip modification in Master route?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.masterFlag = true;
        this.masterRouteName = result;
        result == -1 ? "" : this.save();
      } else {
        this.masterFlag = false;
        this.masterRouteName = null;
        this.save();
      }
    });
  }

  autoSeqDialog() {
    let msg = "This save will auto-sequence the employees in the route!!";
    let msgKey = '';
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ms-2'
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: msg,
      text: msgKey,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
    }).then(result => {
      if (result.value) {
        swalWithBootstrapButtons.fire('Done', msgKey, 'success');
        this.autoSeqFlag = true;
        this.modificationConfirmation();
      } else {
        swalWithBootstrapButtons.fire('Cancelled', 'Not Updated', 'error');
        this.autoSeqFlag = false;
        this.modificationConfirmation();
      }
    });
  }

  validateTripEscort() {
    let flag = true;
    this.tripsList.forEach(trip => {
      if (trip.deviceTripStatus == "not started" && trip.tripDetails.tripUsers.length > 0) {
        if (trip.tripDetails.shift) {
          if (trip.tripDetails.shift.logType == "OUT") {
            let n = trip.tripDetails.tripUsers.length;
            let lastTripUser = trip.tripDetails.tripUsers[n - 1];
            if (!trip.escortStatus && lastTripUser.tripUser.userInfoDetails.gender == "Female") {
              flag = false;
            }
          } else {
            let firstTripUser = trip.tripDetails.tripUsers[0];
            if (!trip.escortStatus && firstTripUser.tripUser.userInfoDetails.gender == "Female") {
              flag = false;
            }
          }
        }
      }
    });
    if (!flag) {
      if (confirm("Are you sure you want to save without escort for female employee?")) {
        return true;
      } else {
        return false;
      }
    }
    return flag;
  }

  formatDatePickerDate(d) {
    let date = d.split("/");
    let dd = date[2] + "-" + date[1] + "-" + date[0] + " 00:00:00";
    return formatDate(dd, "yyyy-MM-dd HH:mm:ss", "en-US");
  }

  save() {
    this.ngxLoader.start();
    if (this.validateExceptionEmps()) {
      let modyfiedList: any = [];
      let removeEmpflag = true;
      let driverstatusflag = true;
      let userName = "";
      let driverstatus = "";
      let locTripCode = "";
      let tripData = this.tripsList;
      this.tripsList.forEach(trip => {
        if (trip.deviceTripStatus == "not started") {
          let torder = 1;
          let userTripList = [];
          if (trip.tripDetails.escortStatus && trip.vehicleCap > trip.tripDetails.tripUsers.length || !trip.tripDetails.escortStatus && trip.vehicleCap + 1 > trip.tripDetails.tripUsers.length) {
            trip.tripDetails.tripUsers.forEach(tuser => {
              if (tuser.tripUser.id != tuser.tripUser.userInfoDetails.id) {
                userName = tuser.tripUser.userInfoDetails.displayName
              };
              let usr = {
                userid: tuser.tripUser.id,
                routedOrder: torder,
                pickupDropTime: tuser.booking ? tuser.booking.bookingId : null
              };
              torder++;
              userTripList.push(usr);
            });
          } else {
            removeEmpflag = false;
            locTripCode = trip.tripCode;
          }
          if (removeEmpflag) {
            let ttrip = {
              escortallocation: trip.escortStatus,
              shiftTimeId: this.shiftid,
              siteId: this.siteid,
              tripDate: this.formatDatePickerDate(this.date),
              vehicletypeid: trip.vehicleTypeId,
              tripusers: userTripList,
              tripId: trip.id,
              autoSeqFlag: this.autoSeqFlag
            };
            modyfiedList.push(ttrip);
          }
        } else {
          driverstatusflag = false;
          locTripCode = trip.tripCode;
          driverstatus = trip.deviceTripStatus;
        }
      });
      if (removeEmpflag && driverstatusflag) {
        let reqData = {
          modifiedList: modyfiedList,
          exceptionList: this.exceptionEmps,
          masterFlag: this.masterFlag,
          // masterRouteName: this.masterRouteName
        };
        this.apiService.doPostRequestWithResponse(ApiConstants.modifytrip, reqData, success => {
          if (success.status == 200) {
            if (success.body.errorMsg) {
              this.ngxLoader.stop();
              this.notifyService.showError(success.body.errorMsg, null);
            } else {
              this.ngxLoader.stop();
              this.notifyService.showSuccess("Trips Modified", null);
              this.onClickBack();
            }
          } else if (success.status == 204) {
            this.ngxLoader.stop();
            this.notifyService.showInfo("Can't Modify running trip ", null);
          }
        }, error => {
          this.ngxLoader.stop();
          if ((error.status && error.status == 504) || error.message == "Request Timed out, Please try again later" || error.message == "Network error occured. Please try again later.") {
            this.notifyService.showWarning("Please wait, modification is been processed in the background...", null)
            this.onClickBack();
          } else {
            if (userName != "") {
              this.notifyService.showWarning(userName + " has not enough data. " + locTripCode + " modified failed", null)
            } else {
              this.notifyService.showWarning("Trip modified failed", null)
            }
          }
        });
      } else if (!driverstatusflag) {
        this.ngxLoader.stop();
        this.notifyService.showInfo(locTripCode + " can't modify.trip is already " + driverstatus, null);
      } else {
        this.ngxLoader.stop();
        this.notifyService.showWarning("Seat Capacity exceeded, Please remove employees from trip : " + locTripCode, null);
      }
    } else {
      this.ngxLoader.stop();
    }
  }

  preViewMap() {
    this.toggleMapDisplay();
    if (this.isOsm) {
      this.loadOpenStreetMap();
    } else {
      this.loadGoogleMap();
    }
    this.showOnMap();
  }

  toggleMapDisplay() {
    if (this.height === "0px") {
      this.height = "400px";
      this.panelBody = "300px";
      this.title = "Hide Map";
      this.icon = "assets/images/hide.svg";
    }
  }

  loadOpenStreetMap() {
    if (this.map) {
      this.map.remove();
    }
    this.loadOsmap();
  }

  loadGoogleMap() {
    this.clearMarker();
    this.loadgmap();
    if (this.tripdetails[0].tripDetails.site) {
      this.markSite(this.tripdetails[0].tripDetails.site);
    }
  }

  clearMarker() {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });
  }

  dragHandler(event) {
    this.cd.detach();
    var offset = 150;
    var scrollValueDown = 10;
    var scrollValueUp = -1 * scrollValueDown;
    if (event.screenY > 0 && event.screenY < offset) {
      window.scrollBy(0, scrollValueUp); // up
    }
    if (event.screenY > window.outerHeight - offset && event.screenY < window.outerHeight) {
      window.scrollBy(0, scrollValueDown); // down
    }
    this.cd.reattach();
  }

  editZone(trip) {
    if (trip.tripDetails.zone != null) {
      this.router.navigateByUrl("/myatom/routing/editzone/" + trip.id);
    } else {
      this.notifyService.showInfo("No zone found", null);
    }
  }

  removeUserFromTrips(trip, user, d) {
    this.removeUser = '';
    if (trip) {
      this.removeUser = trip.id;
    }
    this.cd.reattach();
    let escortCount = 0;
    let arr = [];
    if (trip.escortStatus) {
      escortCount = 1;
    }
    this.tripsList.forEach((trips, t) => {
      arr = trips.tripDetails.tripUsers;
      trips.tripDetails.tripUsers = this.removeDumplicateValue(arr, user);
      if (trip.id == trips.id) {
        this.tripsList[t].tripDetails.tripUsers.splice(
          this.tripsList[t].tripDetails.tripUsers.indexOf(user), 1);
        this.exceptionEmps.splice(this._userIndex, 0, user);
      }
    });
  }

  updateEscortStatusByAdmin(tripId, status) {
    this.apiService.doPutRequestWithResponse(ApiConstants.updateEscortStatusByAdmin + tripId + '&estatus=' + status, '', success => {
      if (success.status == 200) {
        this.notifyService.showSuccess("Escort status updated Successfully.", null);
      }
    }, error => {
      console.log("escort status update:", error);
    })
  }

  onClickMap() {
    if (this.height === "0px") {
      this.height = "400px";
      this.panelBody = "300px";
      this.title = "Hide Map";
      this.icon = "assets/images/hide.svg";
    } else {
      this.height = "0px";
      this.title = "Show Map";
      this.panelBody = "300px";
      this.icon = "assets/images/red-eye.svg"
    }
  }

  onSearchKey(searchKey) {
    if (this.dataFlag == false) {
      this.dataFlag = true;
    }
    if (this.dataFlag == true) {
      if (searchKey !== "") {
        this.tempData = this.tempDataSearch.filter(data => data.userName.toLowerCase().includes(searchKey.toLowerCase()));
      }
      else {
        this.tripsList = this.tripDataTemp;
        this.tempData = [];
        this.userSelected = '';
      }


    }
  }

  searchSelected(value) {
    this.tripsList = this.tripDataTemp;
    this.userSelected = value.userName;
    let valueArray = [];
    this.tripsList.forEach(e1 => {
      e1.usersList.forEach(e2 => {
        if ((e2.userName == value.userName) && (e2.personalNumber == value.personalNumber)) {
          valueArray.push(e1);
          this.tripsList = [];
          this.tripsList = valueArray;
        }
      })
    })
  }

  removeDel() {
    this.exceptionEmps ??= [];
    this.tripsList.forEach(trip => {
      const tripUsers = trip.tripDetails?.tripUsers;
      if (!tripUsers?.length) return;
      const [cancelledUsers, activeUsers] = tripUsers.reduce(
        ([cancelled, active], user) => {
          return user.showStatus === 'Cancel'
            ? [[...cancelled, user], active]
            : [cancelled, [...active, user]];
        },
        [[], []]
      );
      this.exceptionEmps.push(...cancelledUsers);
      trip.tripDetails.tripUsers = activeUsers;
    });
  }

}
