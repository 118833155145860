import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-push-back',
  templateUrl: './admin-push-back.component.html',
  styleUrls: ['./admin-push-back.component.scss']
})
export class AdminPushBackComponent {

  _mTitle = "EUCA";
  public _mFormGroup: FormGroup;
  public mReasonType = new FormControl('', Validators.required);
  _mReasonTypes = [{ value: "Route Exchange" }, { value: "Shortage of vehicles" }, { value: "Additional Route" }, { value: "Type of vehicle changed" }, { value: "APP Issue" }, { value: "Back to back requirement" }, { value: "Out of boundary" }]

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AdminPushBackComponent>,
    @Inject(MAT_DIALOG_DATA) public _mData: any) {

    iconRegistry.addSvgIcon('cancelIcon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/remove-user.svg'));

    this._mFormGroup = this.fb.group({
      'reasonType': this.mReasonType
    });

  }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this._mFormGroup.valid) {
      let data = {
        "mData": this._mData,
        "reasonType": this.mReasonType.value
      }
      this.dialogRef.close(data);
    }
  }
}

