import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.scss']
})
export class AddDriverComponent implements OnInit {

  public _mFormGroup: FormGroup;
  public mDriverName = new FormControl("", Validators.required);
  public mContactNumber = new FormControl(
    null,
    Validators.compose([
      Validators.required,
      Validators.pattern("^[9|8|7|6]{1}[0-9]{9}$")
    ])
  );
  public mLicenseNumber = new FormControl("", Validators.required);

  public _mTitle = "Add Driver";

  constructor(
    public dialogRef: MatDialogRef<AddDriverComponent>,
    private fb: FormBuilder,
    private serviceLocalStorage: ServiceLocalStorage,
    public dialog: MatDialog,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
 
  
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._mFormGroup = this.fb.group({
      driverName: this.mDriverName,
      contactNumber: this.mContactNumber,
      licenseNumber: this.mLicenseNumber,
    })
   }

  ngOnInit() {
    console.log(this.data);
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  discard_special_char_And_String(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  onSubmit() { 
    if (this._mFormGroup.valid) {
      let data1 = {
        "name": this.mDriverName.value,
        "contactNumber": this.mContactNumber.value,
        "vendorMaster": {
          "id": this.data.id
        },
        "licenseNumber": this.mLicenseNumber.value,
        "status": "Active",
        "onCallBooking": true
      }

     this.ngxLoader.start();
      this.apiService.doPostRequestWithResponse(ApiConstants.addDriver,
        data1,
        success => {
          if (success.status == 201) {
            this.notifyService.showSuccess(success.body.message, null);
            this.ngxLoader.stop();
            this.dialogRef.close();
          }

        }, error => {
          this.ngxLoader.stop();
          this.notifyService.showWarning("Oops, something went wrong. Please try again later.", null);
        });
    }
  }

  discard_special_char(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

}
