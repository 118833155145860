<div class="container-fluid" style="overflow: hidden !important;">
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom"
          style="padding-top: 7px;">
          <li [ngbNavItem]="1">
            <a ngbNavLink> Track Trips</a>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
        <div class="card-body">
          <!-- <form class="example-form" [formGroup]="_mFormGroup">
            <div class="row mt-3">
              <div class="col-sm-2">
                <label>Site</label>
                <select class="form-select" [(ngModel)]="selectedSite" placeholder="Site" formControlName="site">
                  <option value="" disabled>Select site
                  </option>
                  <option *ngFor="let site of _mSitesData" [value]="site.siteId">
                    {{ site.siteName }}
                  </option>
                </select>
              </div>
              <div class="col-sm-2 pt-2">
                <label>Date</label>
                <mat-form-field appearance="outline" class="tracktripsField">
                  <input matInput (click)="picker.open()" formControlName="date" [matDatepicker]="picker"
                    placeholder="Date" readonly="readonly">
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

              </div>
              <div class="col-sm-2 pt-2">
                <label>Log type</label>

                <select class="form-select" (change)="onLogTypeChange()" formControlName="logType">
                  <option value="" disabled>Select Log
                  </option>
                  <option *ngFor="let pickDrop of _mPickDropValue" [value]="pickDrop.key">
                    {{ pickDrop.label }}
                  </option>
                </select>

              </div>
              <div class="col-sm-2 pt-2">
                <label>Shift time</label>

                <select class="form-select" formControlName="shiftTime">
                  <option value="" disabled>Select Shift
                  </option>
                  <option *ngFor="let shiftTime of _mShiftTimes" [value]="shiftTime.shiftTimeId">
                    {{ shiftTime.shiftTime }}
                  </option>
                </select>

              </div>
              <div class="col-sm-4" style="display: flex; justify-content: flex-end; align-items: center;">
                <button class="btn red-btn-solid " style="font-size: 14px;" (click)="onSubmit()">
                  Show
                </button>
              </div>
              <div class="col-2  " style="padding-top:2.2rem ;" *ngIf="tripDetailsList">
                <button class="btn red-btn-solid-export" style="font-size: 14px;" (click)="download()">Export
                </button>
              </div>
              <div class="col-2  " style="padding-top:2.2rem ;" *ngIf="allocatedTripFlag">
                <button class="btn red-btn-solid-export" style="font-size: 14px;" (click)="downloadAllocated()">Export
                </button>
              </div>
              <div class="col-2  " style="padding-top:2.2rem ;" *ngIf="unAllocatedTripFlag">
                <button class="btn red-btn-solid-export" style="font-size: 14px;" (click)="downloadUnAllocated()">Export
                </button>
              </div>

            </div>


          </form> -->

          <div class="row">
            <div class="col-sm-12 col-md-12">
              <form [formGroup]="_mFormGroup">
                <div class="d-flex customtracktrips" style="align-items: flex-end;">
                  <div>
                    <label>Site</label>
                    <select class="form-select" [(ngModel)]="selectedSite" placeholder="Site" formControlName="site">
                      <option value="" disabled>Select Site
                      </option>
                      <option *ngFor="let site of _mSitesData" [value]="site.siteId">
                        {{ site.siteName }}
                      </option>
                    </select>
                  </div>
                  <div style="position: relative; top: 22px;">
                    <label>Date</label>
                    <mat-form-field appearance="outline" class="tracktripsField">
                      <input matInput (click)="picker.open()" formControlName="date" [matDatepicker]="picker"
                        placeholder="Date" readonly="readonly">
                      <mat-datepicker-toggle matSuffix [for]="picker">
                        <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div>
                    <label>Log type</label>
                    <select class="form-select" (change)="onLogTypeChange()" formControlName="logType">
                      <option value="" disabled>Select Log</option>
                      <option *ngFor="let pickDrop of _mPickDropValue" [value]="pickDrop.key">
                        {{ pickDrop.label }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <label>Shift time</label>
                    <select class="form-select" formControlName="shiftTime">
                      <option value="" disabled>Select Shift</option>
                      <option *ngFor="let shiftTime of _mShiftTimes" [value]="shiftTime.shiftTimeId">
                        {{ shiftTime.shiftTime }}
                      </option>
                    </select>
                  </div>
                  <div style="display: flex; gap: 35px; justify-content: flex-end;">
                    <button class="btn track-btn-solid" (click)="onSubmit()">Show</button>
                    <img style="position: relative;" src="./assets/images/excel.svg" (click)="download()"
                      matTooltip="Export To Excel" *ngIf="tripDetailsList">
                    <img style="position: relative;" src="./assets/images/excel.svg" (click)="downloadAllocated()"
                      matTooltip="Export To Excel" *ngIf="allocatedTripFlag">
                    <img style="position: relative;" src="./assets/images/excel.svg" (click)="downloadUnAllocated()"
                      matTooltip="Export To Excel" *ngIf="unAllocatedTripFlag">
                  </div>
                </div>
              </form>
            </div>
          </div>


          <div class="row mt-4" *ngIf="tripSummaryFlag">
            <div class="col-sm-12 col-md-12">
              <table class="responsive summary tdw-position-relative track-tripszIndex">
                <thead class="tdw-bottom-line mat-color--grey-100 tdw-card-thin animated
              bounce">
                  <th class="tracktrips-padding tdw--text-align-right sort-remove font-size-summary">
                    Summary
                  </th>
                  <th class="tracktrips-padding tdw--text-align-right sort-remove font-size-summary">
                    Total Assigned Trips : {{assignedTripCountArr[0].assignedTrips}}
                  </th>
                  <th class="tracktrips-padding tdw--text-align-right sort-remove pointer font-size-summary"
                    (click)="showAllocatedTrips()">
                    Allocated Trips: {{assignedTripCountArr[0].allocatedTrips}}
                  </th>
                  <th class="tracktrips-padding tdw--text-align-right sort-remove pointer font-size-summary"
                    (click)="showUnAllocatedTrips()">
                    UnAllocated Trips : {{assignedTripCountArr[0].unAllocatedTrips}}
                  </th>
                  <th *ngIf="tempVehicle">
                    <img src="assets/images/carplus.svg" (click)="AddTempVeh()" title="Add Ad-Hoc Vehicle"
                      style="cursor: pointer;">
                  </th>
                  <th>
                    <div class="dropdown-container" ngbDropdown>
                      <button type="button" class="btn" id="page-header-user-dropdown" style="border: none;"
                        title="Color Info" ngbDropdownToggle>
                        <img src="assets/images/infor.svg" alt="Info Icon">
                      </button>
                      <div class="dropdown-menu dropdown-menu-end" style="width: 145%;" ngbDropdownMenu>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt1 me-3"></div>Normal
                        </a>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt2 me-3"></div>Expectant Mother
                        </a>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt3 me-3"></div>Hearing Impaired
                        </a>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt4 me-3"></div>Additional Support
                        </a>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt5 me-3"></div>PWD
                        </a>
                      </div>
                    </div>
                  </th>
                </thead>
              </table>
            </div>
          </div>
          <div class="tracktrips-scroll" *ngIf="tripDetailsList">
            <div class="row">
              <div class="col-sm-3 mt-3">
                <div class="d-flex justify-content-between" style="width: 250px;">
                  <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
                    placeholder="Search by EmpId/Name" [(ngModel)]="searchTrip">
                </div>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showOrHideAL"
                  [checked]="(showOrHideAL == true) ? true : false" style="position: relative; top: 10px;"
                  (valueChange)="showOrHideTitle($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  {{mTitle}}</span>
              </div>
              <div class="col-sm-2 mt-2">
                <span
                  style="position: relative; left: 7px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Address</span>
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="addressOrAL"
                  [checked]="(addressOrAL == true) ? true : false" style="position: relative; top: 10px; left: 18px;"
                  (valueChange)="showOrHideAddress($event)"></ui-switch>
                <span
                  style="position: relative; left: 30px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">A/L</span>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch style="position: relative; top: 10px;" defaultBoColor="#dfdfdf" color="#34c38f"
                  [(ngModel)]="showContact" [checked]="(showContact == true) ? true : false"
                  (valueChange)="showOrHideContact($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  Contact</span>
              </div>

              <div class="col-sm-3 mt-3">
                <button class="btn-solid-select-all" style="position: relative; right: 2px;"
                  (click)="onSelectAll(tripDetailsData, escortListData)">Select
                  All</button>
              </div>
            </div>

            <!-- loop starts here -->


            <div *ngFor="let trips of tripDetailsData |
      allocatedTripFilter : searchTrip; let i=index;">
              <div class="card p-0 mb-2">
                <div class="card-body">
                  <!-- <div class="row"
                    style="background-color: #FBFCFF !important;padding-top: 7px; margin-left: 1px;margin-right: 1px; height: 50px; box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px -2px rgba(0, 0, 0, 0.2);">
                    <div class="col-3 mt-2"
                      style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip ID : <span
                        class="trip-code" (click)=maptrackview(trips.tripId)>{{trips.tripCode}}</span></div>
                    <div class="col-3 mt-2"
                      style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip Date :
                      {{trips.tripDate}}</div>
                    <div class="col-3 mt-2"
                      style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Shift :
                      {{trips.logType}}-{{trips.shiftTime}}</div>
                    <div class="col-3 mt-2"
                      style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">DeviceTripStatus
                      :
                      {{trips.tripVendorAssign.DeviceTripStatus}}</div>
                    <div>
                      <mat-checkbox class="tracktripsCheckbox" type="checkbox" [checked]="trips.checked"
                        (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                    </div>

                  </div> -->
                  <div class="row">
                    <div class="col-sm-12 col-md-12" style="background-color: #FBFCFF !important;padding-top: 10px; margin-left: 1px;margin-right: 1px; 
                      height: 45px; box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14), 
                      0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px -2px rgba(0, 0, 0, 0.2);">
                      <div class="summarytracktrips">
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            ID
                            : </span><span class="trip-code" [ngStyle]="trips.tripsColor ? 
                            {'color': '#041D41', 'opacity': '0.7', 'background': trips.tripsColor, 'border': '1px solid' + trips.tripsColor, 
                            'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'} : 
                            {'color': '#7C7D84', 'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'}"
                            (click)=maptrackview(trips.tripId)>{{getExtractedSubstring(trips.tripCode)}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Date
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripDate}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Shift
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.logType}}-{{trips.shiftTime}}</span>
                        </div>
                        <div>
                          <span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Vehicle
                            Type
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.vehicleType}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Status
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripVendorAssign.DeviceTripStatus}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Escort
                            Trip
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.escortStatus}}</span>
                        </div>
                        <!-- <div>
                          <span>
                            <a href="javascript: void(0);" title="Audit" (click)="getAudit(trips)">
                              <img src="assets/images/icons/audit.svg">
                            </a> </span>
                        </div>
                        <div *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                          <span class="material-icons-outlined" style="cursor: pointer; color: #858DA9;"
                            title="Send Back To Admin" (click)="sendBackToAdmin(trips)">
                            assignment_return
                          </span>
                        </div>
                        <div *ngIf="tripDateValidate && ((trips?.driver) ?false : true)  && !assignVehicle">
                          <span class="material-icons-outlined" style="cursor: pointer; color: #858DA9;"
                            title="Send Back To Admin" (click)="sendBackToAdmin(trips)">
                            assignment_return
                          </span>
                        </div>
                        <div *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                          <span class="material-icons-outlined" style="cursor: pointer; color: #858DA9;"
                            title="Send Back To Admin" (click)="sendBackToAdmin(trips)">
                            assignment_return
                          </span>
                        </div>

                        <div>
                          <mat-checkbox class="tracktripsCheckbox" type="checkbox" [checked]="trips.checked"
                            style="position: relative; bottom: 9px;"
                            (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                        </div> -->

                        <!-- <div class="col-2 pt-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true) ">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 40px;">
                          Send Back To Admin
                        </button>
                      </div> -->

                        <!-- <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && ((trips?.driver) ?false : true)  && !assignVehicle">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 80px;">
                          Send Back To Admin
                        </button>
                      </div> -->

                        <div>
                          <span class="action-container">
                            <a href="javascript: void(0);" title="Audit" (click)="getAudit(trips)">
                              <img src="assets/images/icons/audit.svg">
                            </a>
                            <span *ngIf="tripDateValidate && !((trips?.driver) ?false : true)" style="cursor: pointer;"
                              class="material-icons-outlined" (click)="sendBackToAdmin(trips)"
                              title="Send Back To Admin">
                              assignment_return
                            </span>

                            <span *ngIf="tripDateValidate && ((trips?.driver) ?false : true)  && !assignVehicle"
                              style="cursor: pointer;" class="material-icons-outlined" (click)="sendBackToAdmin(trips)"
                              title="Send Back To Admin">
                              assignment_return
                            </span>

                            <span *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)"
                              style="cursor: pointer;" class="material-icons-outlined" (click)="sendBackToAdmin(trips)"
                              title="Send Back To Admin">
                              assignment_return
                            </span>
                            <mat-checkbox class="tracktripsCheckbox" type="checkbox" [checked]="trips.checked"
                              style="position: relative; bottom: 9px; left: 0px;"
                              (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-12 col-md-12">
                      <form [formGroup]="_mFormGroupForTrackTrips">
                        <div class="row">
                          <!-- <div class="col-2 pt-3">
                        <div style="font-size: 13px;">Vehicle
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                          {{trips.vehicleType}}</div>
                      </div> -->
                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                              <input type="text" [ngModelOptions]="{standalone: true}" (keyup)="clearDriver(vehicleSearch[i],i,
                        getIndex(trips))" (keypress)="omit_special_char_And_String($event)" maxlength="12"
                                [(ngModel)]="vehicleSearch[i]" placeholder="Vehicle Number" matInput
                                [matAutocomplete]="vehicleNoAuto" (input)="searchFilter($event)" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                        == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #vehicleNoAuto="matAutocomplete"
                                (optionSelected)="vehicleSearchSubmit(trips,$event,getIndex(trips))">
                                <mat-option *ngFor="let vehicle of
                        vehicleListData | vehicleSearchFilter  : vehicleSearch[i]"
                                  style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="vehicle.registrationNo">{{vehicle.registrationNo}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                              <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="driverSearch[i]"
                                (keypress)="omit_special_char_And_String($event)" maxlength="30"
                                placeholder="Choose Driver" matInput [matAutocomplete]="driverName" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                        == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #driverName="matAutocomplete">
                                <mat-option *ngFor="let driver of
                          trips.driverList | vehicleDriverSearchFilter : driverSearch[i]" (onSelectionChange)="driverSearchSubmit(trips,driver,
                          getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="driver.name">{{driver.name}}</mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.tripVendorAssign.DeviceTripStatus == 'stopped'">
                            <mat-form-field appearance="outline" class="escort-tracktrip">
                              <mat-select placeholder="Escort User" [value]="trips?.tripVendorAssign?.escort?.id"
                                [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                                    == 'not started') ? false : true) ? true :
                                    (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                    getIndex(trips))">
                                <mat-option class="escort-checkIconHide"
                                  style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="trips.tripVendorAssign.escort?.id">
                                  {{trips.tripVendorAssign.escort?.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.tripVendorAssign.DeviceTripStatus !== 'stopped'">
                            <mat-form-field appearance="outline" class="escort-tracktrip"
                              [ngClass]="{'mandatory-escortv2': trips?.tripVendorAssign?.escort && isFieldHighlighted(trips?.tripVendorAssign?.escort?.id)}">
                              <mat-select placeholder="Escort User" [value]="trips?.escort?.id" [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                                      == 'not started') ? false : true) ? true :
                                      (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                      getIndex(trips))">
                                <mat-option class="escort-checkIconHide" *ngFor="let escort of
                                          escortListData"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="escort.id">
                                  {{escort.name}}
                                </mat-option>
                              </mat-select>
                              <p class="error-msg"
                                *ngIf="trips?.tripVendorAssign?.escort && showErrorMessage(trips?.tripVendorAssign?.escort?.id)">
                                Please select another escort.
                              </p>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType =='IN'">
                            <mat-form-field appearance="outline" class="escort-tracktrip">
                              <input placeholder="Travel time (min)" value="{{trips.tripTravelTime}}" type="text"
                                maxlength="4" (keypress)="discard_special_char_And_String($event)"
                                (keyup)="setTime($event,trips)" matInput>
                              <img matSuffix src="assets/images/save.svg" height="17"
                                style="margin-bottom: 22px; padding-right: 5px; cursor: pointer;" (click)="updateTravelTime(trips,
                        getIndex(trips))" matTooltip="Update">
                            </mat-form-field>
                          </div>


                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType
              =='OUT'">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Travel Time :
                              {{trips.tripTravelTime}}</div>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Trip Distance
                              :
                              {{trips.tripDistance | number :'1.2-2'}} Km</div>
                          </div>


                          <!-- <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                        <button (click)="setDriverAndSendSMS(trips,escortListData,
                  getIndex(trips))" class="btn red-btn-solid-other " style="position:relative;left: 95px;">
                          Set Driver & Send SMS
                        </button>
                      </div> -->

                          <!-- <div class="col-2 pt-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                        <button (click)="setDriverAndSendSMS(trips,escortListData,
                getIndex(trips))" class="btn red-btn-solid-other" style="position:relative;left: 82px;">
                          Re-Send SMS
                        </button>
                      </div> -->


                          <!-- <div class="col-2 pt-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true) ">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 40px;">
                          Send Back To Admin
                        </button>
                      </div> -->

                          <!-- <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && ((trips?.driver) ?false : true)  && !assignVehicle">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 80px;">
                          Send Back To Admin
                        </button>
                      </div> -->

                          <!-- <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 70px;">
                          Send Back To Admin
                        </button>
                      </div> -->
                          <!-- <div class="col-2 pt-2">
                        <button class="btn red-btn-solid-other" (click)="removeVDAllocation(trips)"
                          *ngIf="tripDateValidate && !((trips?.driver) ?false : true)"
                          style="position:relative;left: 20px;">
                          Remove Allocation
                        </button>
                      </div> -->
                        </div>

                        <div class="row mt-3">
                          <div class="col-sm-2 col-md-2"
                            *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                        getIndex(trips))" class="btn red-btn-solid-other" style="padding: 7px 36px;">
                              Set Driver & Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                        getIndex(trips))" class="btn red-btn-solid-other" style="padding: 7px 63px;">
                              Re-Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2"
                            *ngIf="['not started', 'stopped'].includes(trips.tripVendorAssign.DeviceTripStatus)">
                            <button class="btn red-btn-solid-other" (click)="removeVDAllocation(trips)"
                              *ngIf="tripDateValidate && !((trips?.driver) ?false : true)" style="padding: 7px 45px;">
                              Remove Allocation
                            </button>
                          </div>
                          <div class="col-sm-2 col-md-2" *ngIf="trips.tripVendorAssign.DeviceTripStatus == 'running'">
                            <button class="btn red-btn-solid-other" (click)="onReallocation(trips)"
                              style="padding: 7px 60px;">
                              Re-Allocation
                            </button>
                          </div>
                          <!-- <div class="col-2 pt-3" style="font-size: 13px;">Escort Trip :
                        {{trips.escortStatus}}</div> -->
                          <!-- <div class="col-2 pt-2" style="position: relative; left: 4px;">
                        <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                          <mat-select placeholder="Escort user" [value]="trips?.escort?.id" [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                                    == 'not started') ? false : true) ? true :
                                    (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                    getIndex(trips))">
                            <mat-option *ngFor="let escort of
                                        escortListData"
                              style=" font-size: 13px !important; font-weight: 400 !important;" [value]="escort.id">
                              {{escort.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div> -->

                          <!-- <div class="col-2 pt-2" *ngIf="trips.logType =='IN'" style="position: relative; left: 9px;">
                        <mat-form-field appearance="outline" class="customFieldInSideDriver">
                          <input placeholder="Travel time (min)" value="{{trips.tripTravelTime}}" type="text"
                            maxlength="4" (keypress)="discard_special_char_And_String($event)"
                            (keyup)="setTime($event,trips)" matInput> -->
                          <!-- <mat-icon class="update-traveltime" matSuffix (click)="updateTravelTime(trips,
                            getIndex(trips))" matTooltip="Update now">save
                      </mat-icon> -->
                          <!-- <img matSuffix src="assets/images/save.svg" height="17"
                            style="margin-top: -22px; padding-right: 5px; cursor: pointer;" (click)="updateTravelTime(trips,
                        getIndex(trips))" matTooltip="Update now">
                        </mat-form-field>
                      </div> -->

                          <!-- 
                      <div class="col-2 " *ngIf="trips.logType
              =='OUT'">
                        <div class="vehicle-name" style="font-size: 13px;position: relative;left: 90px">Travel Time :
                          {{trips.tripTravelTime}}</div>
                      </div>

                      <div class="col-2 ">
                        <div class="vehicle-name" style="font-size: 13px;position: relative;left: 90px">Trip Distance
                          :
                          {{trips.tripDistance | number :'1.2-2'}} Km</div>
                      </div> -->
                          <!-- <div class="col-2 pt-2">
                        <button class="btn red-btn-solid-other" style="position: relative;left: 105px;"
                          (click)="getAudit(trips)">
                          Audit Log
                        </button>
                      </div> -->
                        </div>

                        <div class="row mt-1">
                          <div class="col-sm-12 col-md-12">
                            <table class="table table-track-trips m-0">
                              <thead>
                                <th>Employee Id</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Gender</th>
                                <th *ngIf="address">Address</th>
                                <th *ngIf="areaLandmark">Area</th>
                                <th *ngIf="areaLandmark">Landmark</th>
                                <th>Show Status </th>
                                <th>Distance</th>
                                <th>
                                  <span
                                    style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
                                    <span>Time</span>
                                    <button class="btn red-btn-solid-time" *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(trips,
                          getIndex(trips))">Update</button>
                                  </span>
                                </th>
                                <th *ngIf="tripDateValidate &&
                          ((trips?.driver) ?
                          true : false)" style="text-align: center;"> Send SMS</th>
                              </thead>

                              <tbody>
                                <tr *ngFor="let users of trips.tripUserList">
                                  <td> <span class="badge bfont"
                                      [ngStyle]="{'color': '#041D41', 'opacity': '0.7', 'background': users.userColor, 'border': '1px solid' + users.userColor}">
                                      {{users.tripUser.userInfoDetails.employeeId}}
                                    </span>
                                  </td>
                                  <td>{{users.tripUser.userInfoDetails.firstName}}</td>
                                  <td *ngIf="!showContact">{{users.tripUser.userInfoDetails.contactNumber.substring(0,
                                    1)}}*******{{users.tripUser.userInfoDetails.contactNumber.substring(8, 10)}}</td>
                                  <td *ngIf="showContact">
                                    {{users.tripUser.userInfoDetails.contactNumber}}
                                  </td>
                                  <td>
                                    {{users.tripUser.userInfoDetails.gender == null ? "NA" :
                                    users.tripUser.userInfoDetails.gender == 'Male' ? 'M' :
                                    users.tripUser.userInfoDetails.gender == 'Female' ? 'F' : 'O'}}
                                  </td>
                                  <!-- <td>
                                    {{users.landmark.area.area}}
                                  </td>
                                  <td *ngIf="!showHideAddress">
                                    {{users.landmark.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text" *ngIf="showHideAddress">
                                    {{users.landmark.landmark}}
                                  </td> -->
                                  <td class="landmark-wrap-text" *ngIf="address && !showHideAddress">
                                    {{ users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") + ", " +
                                    (users.landmark.landmark !== null ? users.landmark.landmark : "NA") :
                                    users.userLandmark !== null ?
                                    users.userLandmark.area.area + ", " + users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address}}
                                  </td>
                                  <td *ngIf="showHideAddress && mTitle == 'Address'">
                                    {{users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area.substring(0, 5) : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area.substring(0, 5) :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address.substring(0, 5)}}*********
                                  </td>
                                  <td *ngIf="areaLandmark">
                                    {{users.landmark.type == "C" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area : users.tripUser.userInfoDetails.pickUpDrop ==
                                    null ? "NA" : users.tripUser.userInfoDetails.pickUpDrop.area.area}}
                                  </td>
                                  <td *ngIf="showHideLandmark && mTitle == 'Landmark'">
                                    {{users.landmark.type == "C" ? (users.landmark.landmark !== null ?
                                    users.landmark.landmark.substring(0, 5) : "NA") : users.userLandmark
                                    !== null ? users.userLandmark.landmark.substring(0, 5) :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text" *ngIf="areaLandmark && !showHideLandmark">
                                    {{users.landmark.type == "C" ? users.landmark.landmark : users.userLandmark
                                    !== null ? users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark}}
                                  </td>
                                  <td>
                                    {{users.showStatus}}
                                  </td>
                                  <td>
                                    {{users.empTripDistance | number
                                    :'1.2-2'}} Km
                                  </td>
                                  <td *ngIf="trips.logType=='OUT'">
                                    {{users.pickupDropTime ?
                                    users.pickupDropTime.slice(0,5) :
                                    'NA'}}
                                  </td>
                                  <td>
                                    <!-- <mat-form-field appearance="outline" class="customFieldTime" *ngIf="trips.logType =='IN'">
                            <input placeholder="Time*(Eg: 00:00)" matInput
                              (keypress)="omit_special_char_And_String1($event)" autocomplete="off" maxlength="5"
                              value="{{users.pickupDropTime ?users.pickupDropTime.slice(0,5) : ''}}"
                              (change)="onTimeValidate($event, users, trips, getIndex(trips))">
                          </mat-form-field> -->
                                    <input class="input-field me-1" placeholder="Time* (Eg: 00:00)"
                                      *ngIf="trips.logType =='IN'" (keypress)="omit_special_char_And_String1($event)"
                                      autocomplete="off" maxlength="5"
                                      value="{{users.pickupDropTime ?users.pickupDropTime.slice(0,5) : ''}}"
                                      (change)="onTimeValidate($event, users, trips, getIndex(trips))">
                                    <!-- <span *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(emp)">
                            <img src="assets/images/save.svg" style="cursor: pointer;"  height="15" matTooltip="Update">
                          </span> -->
                                  </td>
                                  <td style="text-align: center;" *ngIf="tripDateValidate &&
                            ((trips?.driver) ?
                            true : false)">
                                    <button class="red-btn-solid-resend"
                                      (click)="resendOtp(users, trips)">Resend_OTP</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-md-2 mt-3">
              <button class="btn red-btn-solid-other" (click)="onSubmitAloc()">Submit Allocation
              </button>
            </div>
          </div>

          <!-- Allocated Trips -->


          <!-- <div *ngIf="allocatedTripFlag"> -->
          <!-- <div class="row">
              <div class="col-sm-5">
                <div class="d-flex justify-content-between mt-3" style="width: 250px;">
                  <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
                    placeholder="Search by EmpId/Name" [(ngModel)]="searchTrip">
                </div>
              </div>
              <div class="col-sm-7" style="padding:1rem">
                <button class="btn-solid-select-all" (click)="onSelectAll(allocatedTripArr, escortListData)">Select
                  All</button>
              </div>
            </div> -->

          <!-- <div class="row">
              <div class="col-3 pt-1" style="padding: 5px;">
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showHideAddress"
                  (valueChange)="showOrHideAddress($event)"></ui-switch>
                <span style="position: relative; right: -15px; top: 5px; font-size: 13px;">Show/Hide Landmark</span>
              </div>
              <div class="col-3 pt-1" style="padding: 5px;">
                <ui-switch style="left: 10px;" defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showContact"
                  (valueChange)="showOrHideContact($event)"></ui-switch>
                <span style="position: relative; right: -15px; top: 5px; font-size: 13px;">Show/Hide Contact</span>
              </div>
            </div> -->

          <!-- <div *ngFor="let trips of allocatedTripArr |
        allocatedTripFilter : searchTrip; let i=index;">
              <div class="card p-0 m-0">
                <div class="card-body p-0 m-0">

                  <div class="row"
                    style="background-color: #ddd !important;padding-top: 7px; margin-left: 1px;margin-right: 1px; height: 50px;">
                    <div class="col-3 pt-1" style="font-size: 13px;">Trip ID : <span class="trip-code"
                        (click)=maptrackview(trips.tripId)>{{trips.tripCode}}</span></div>
                    <div class="col-3 pt-1" style="font-size: 13px;">Trip Date : {{trips.tripDate}}</div>
                    <div class="col-3 pt-1" style="font-size: 13px;">Shift :
                      {{trips.logType}}-{{trips.shiftTime}}</div>
                    <div class="col-3 pt-1" style="font-size: 13px;">DeviceTripStatus :
                      {{trips.tripVendorAssign.DeviceTripStatus}}</div>
                    <div>
                      <mat-checkbox class="tracktripsCheckbox" type="checkbox" [checked]="trips.checked"
                        (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                    </div>

                  </div> -->


          <!-- 
                  <form class="example-form" [formGroup]="_mFormGroupForTrackTrips">

                    <div class="row">
                      <div class="col-2 pt-3">
                        <div style="font-size: 13px;">Vehicle
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                          {{trips.vehicleType}}</div>
                      </div>
                      <div class="col-2">
                        <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                          <input type="text" [ngModelOptions]="{standalone: true}" (keyup)="clearDriver(vehicleSearch[i],i,
                        getIndex(trips))" (keypress)="omit_special_char_And_String($event)" maxlength="12"
                            [(ngModel)]="vehicleSearch[i]" placeholder="Vehicle number" matInput
                            [matAutocomplete]="vehicleNoAuto" (input)="searchFilter($event)" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                        == 'not started') ? false : true">
                          <img matSuffix src="assets/images/search.svg" height="27"
                            style="margin-top: -22px;padding-right: 5px;">
                          <mat-autocomplete #vehicleNoAuto="matAutocomplete">
                            <mat-option *ngFor="let vehicle of
                        vehicleListData | vehicleSearchFilter  : vehicleSearch[i] " (onSelectionChange)="vehicleSearchSubmit(trips,vehicle,
                              getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                              [value]="vehicle.registrationNo">{{vehicle.registrationNo}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <div class="col-2">
                        <mat-form-field appearance="outline" class="customFieldInSideDriver">
                          <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="driverSearch[i]"
                            (keypress)="omit_special_char_And_String($event)" maxlength="30" placeholder="Driver"
                            matInput [matAutocomplete]="driverName" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                          == 'not started') ? false : true">
                          <img matSuffix src="assets/images/search.svg" height="27"
                            style="margin-top: -22px;padding-right: 5px;">
                          <mat-autocomplete #driverName="matAutocomplete">
                            <mat-option *ngFor="let driver of
                            trips.driverList | vehicleDriverSearchFilter : driverSearch[i]" (onSelectionChange)="driverSearchSubmit(trips,driver,
                            getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                              [value]="driver.name">{{driver.name}}</mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div> -->


          <!-- <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                        <button (click)="setDriverAndSendSMS(trips,escortListData,
                  getIndex(trips))" class="btn red-btn-solid-other " style="position:relative;left: 95px;">
                          Set Driver & Send SMS
                        </button>
                      </div> -->

          <!-- <div class="col-2 pt-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                        <button (click)="setDriverAndSendSMS(trips,escortListData,
                getIndex(trips))" class="btn red-btn-solid-other" style="position:relative;left: 82px;">
                          Re-Send SMS
                        </button>
                      </div> -->


          <!-- <div class="col-2 pt-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true) ">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 40px;">
                          Send Back To Admin
                        </button>
                      </div> -->

          <!-- <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && ((trips?.driver) ?false : true)  && !assignVehicle">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 80px;">
                          Send Back To Admin
                        </button>
                      </div> -->

          <!-- <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 70px;">
                          Send Back To Admin
                        </button>
                      </div> -->
          <!-- <div class="col-2 pt-2">
                        <button class="btn red-btn-solid-other" (click)="removeVDAllocation(trips)"
                          *ngIf="tripDateValidate && !((trips?.driver) ?false : true)"
                          style="position:relative;left: 20px;">
                          Remove Allocation
                        </button>
                      </div> -->
          <!-- </div>


                    <div fxLayout="row">
                      <div class="col-2 pt-3" style="font-size: 13px;">Escort Trip :
                        {{trips.escortStatus}}</div>
                      <div class="col-2 pt-2">
                        <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                          <mat-select placeholder="Escort user" [value]="trips?.escort?.id" [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                                      == 'not started') ? false : true) ? true :
                                      (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                      getIndex(trips))">
                            <mat-option *ngFor="let escort of
                                          escortListData"
                              style=" font-size: 13px !important; font-weight: 400 !important;" [value]="escort.id">
                              {{escort.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-2 pt-2" *ngIf="trips.logType =='IN'">
                        <mat-form-field appearance="outline" class="customFieldInSideDriver">
                          <input placeholder="Travel time (min)" formControlName="travelTime"
                            value="{{trips.tripTravelTime}}" type="text" maxlength="4"
                            (keypress)="discard_special_char_And_String($event)" (keyup)="setTime($event,trips)"
                            matInput>
                          <mat-icon class="update-traveltime" matSuffix (click)="updateTravelTime(trips,
                              getIndex(trips))" matTooltip="Update now">save
                          </mat-icon>
                        </mat-form-field>
                      </div>


                      <div class="col-2 " *ngIf="trips.logType
                    =='OUT'">
                        <div class="vehicle-name" style="font-size: 13px;position: relative;left: 90px">Travel Time :
                          {{trips.tripTravelTime}}</div>
                      </div>

                      <div class="col-2 ">
                        <div class="vehicle-name" style="font-size: 13px;position: relative;left: 90px">Trip Distance
                          :
                          {{trips.tripDistance | number :'1.2-2'}} Km</div>
                      </div>
                      <div class="col-2 pt-2">
                        <button class="btn red-btn-solid-other" style="position: relative;left: 105px;"
                          (click)="getAudit(trips)">
                          Audit Log
                        </button>
                      </div>
                    </div>

                    <div fxLayout="row" style="overflow-y: auto; border: 1px solid #efe5e5; border-radius: 5px;">
                      <ng-container>
                        <table fxFlex class="responsive tdw-position-relative
            tdw-padding-6">
                          <thead class="tdw-bottom-line mat-color--grey-100 tdw-card-thin">
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                tdw-padding-6 header-width1">
                              <ng-container>
                                EmployeeId
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                tdw-padding-6 header-width1">
                              <ng-container>
                                Name
                              </ng-container>
                            </th>

                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                tdw-padding-6 header-width1">
                              <ng-container>
                                Number
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                tdw-padding-6 header-width1">
                              <ng-container>
                                Gender
                              </ng-container>
                            </th>

                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                tdw-padding-6 header-width1">
                              <ng-container>
                                Area
                              </ng-container>
                            </th>

                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                tdw-padding-6 header-width1">
                              <ng-container>
                                LandMark
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                tdw-padding-6 header-width1">
                              <ng-container>
                                Show Status
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                tdw-padding-6 header-width1">
                              <ng-container>
                                Distance
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-center sort-remove
                tdw-padding-6 header-width1">
                              <ng-container>
                                Time<button class="red-btn-solid-time" *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(trips,
                          getIndex(trips))">Update</button>
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                tdw-padding-6 header-width1" *ngIf="tripDateValidate &&
                ((trips?.driver) ?
                true : false)">
                              <ng-container>
                                Send SMS
                              </ng-container>
                            </th>
                          </thead>
                          <tbody class="mat-color--white tdw-card-thin tdw-border-2">
                            <ng-container>
                              <tr *ngFor="let users of trips.tripUserList">
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.employeeId}}
                                  </ng-container>
                                </th>


                                <th class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.firstName}}
                                  </ng-container>
                                </th>
                                <th *ngIf="!showContact" class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.contactNumber.substring(0,
                                    1)}}*******{{users.tripUser.userInfoDetails.contactNumber.substring(8, 10)}}
                                  </ng-container>
                                </th>
                                <th *ngIf="showContact" class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.contactNumber}}
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.gender}}
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.landmark.area.area}}
                                  </ng-container>
                                </th>

                                <th *ngIf="!showHideAddress" class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.landmark.landmark.substring(0, 5)}}*********
                                  </ng-container>
                                </th>
                                <th *ngIf="showHideAddress" class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.landmark.landmark}}
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.showStatus}}
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.empTripDistance | number
                                    :'1.2-2'}} Km
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800">
                                  <ng-container *ngIf="trips.logType=='OUT'">
                                    {{users.pickupDropTime ?
                                    users.pickupDropTime.slice(0,5) :
                                    'NA'}}
                                  </ng-container>
                                  <mat-form-field appearance="outline" class="customFieldTime"
                                    *ngIf="trips.logType =='IN'">
                                    <input placeholder="Time*(Eg: 00:00)" matInput
                                      (keypress)="omit_special_char_And_String1($event)" autocomplete="off"
                                      maxlength="5"
                                      value="{{users.pickupDropTime ?users.pickupDropTime.slice(0,5) : ''}}"
                                      (change)="onTimeValidate($event, users, trips, getIndex(trips))">
                                  </mat-form-field>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                              tdw-font-12 tdw-font-open-sans tdw-font-normal
                              mat-color-text--grey-800" *ngIf="tripDateValidate &&
                              ((trips?.driver) ?
                              true : false)">
                                  <ng-container>
                                    <button class="red-btn-solid-resend"
                                      (click)="resendOtp(users, trips)">Resend_OTP</button>
                                  </ng-container>
                                </th>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </ng-container>
                    </div>
                  </form>
                </div>
              </div>
            </div> -->
          <!-- <div class="col-2 d-flex">
              <button class="btn red-btn-solid-other" (click)="onSubmitAloc()">Submit Allocation
              </button>
            </div>
            </div> -->

          <!-- New Allocated Trips -->

          <div class="tracktrips-scroll" *ngIf="allocatedTripFlag">
            <div class="row">
              <div class="col-sm-3 mt-3">
                <div class="d-flex justify-content-between" style="width: 250px;">
                  <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
                    placeholder="Search by EmpId/Name" [(ngModel)]="searchTrip">
                </div>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showOrHideAL"
                  [checked]="(showOrHideAL == true) ? true : false" style="position: relative; top: 10px;"
                  (valueChange)="showOrHideTitle($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  {{mTitle}}</span>
              </div>
              <div class="col-sm-2 mt-2">
                <span
                  style="position: relative; left: 7px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Address</span>
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="addressOrAL"
                  [checked]="(addressOrAL == true) ? true : false" style="position: relative; top: 10px; left: 18px;"
                  (valueChange)="showOrHideAddress($event)"></ui-switch>
                <span
                  style="position: relative; left: 30px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">A/L</span>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch style="position: relative; top: 10px;" defaultBoColor="#dfdfdf" color="#34c38f"
                  [(ngModel)]="showContact" [checked]="(showContact == true) ? true : false"
                  (valueChange)="showOrHideContact($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  Contact</span>
              </div>

              <div class="col-sm-3 mt-3">
                <button class="btn-solid-select-all" style="position: relative; right: 2px;"
                  (click)="onSelectAll(tripDetailsData, escortListData)">Select
                  All</button>
              </div>
            </div>

            <!-- loop starts here -->


            <div *ngFor="let trips of allocatedTripArr |
        allocatedTripFilter : searchTrip; let i=index;">
              <div class=" card p-0 mb-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12" style="background-color: #FBFCFF !important;padding-top: 10px; margin-left: 1px;margin-right: 1px; 
                      height: 45px; box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14), 
                      0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px -2px rgba(0, 0, 0, 0.2);">
                      <div class="summarytracktrips">
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            ID
                            : </span><span class="trip-code" [ngStyle]="trips.tripsColor ? 
                            {'color': '#041D41', 'opacity': '0.7', 'background': trips.tripsColor, 'border': '1px solid' + trips.tripsColor, 
                            'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'} : 
                            {'color': '#7C7D84', 'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'}"
                            (click)=maptrackview(trips.tripId)>{{getExtractedSubstring(trips.tripCode)}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Date
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripDate}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Shift
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.logType}}-{{trips.shiftTime}}</span>
                        </div>
                        <div>
                          <span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Vehicle
                            Type
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.vehicleType}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Status
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripVendorAssign.DeviceTripStatus}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Escort
                            Trip
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.escortStatus}}</span>
                        </div>

                        <div>
                          <span class="action-container">
                            <a href="javascript: void(0);" title="Audit" (click)="getAudit(trips)">
                              <img src="assets/images/icons/audit.svg">
                            </a>
                            <span *ngIf="tripDateValidate && !((trips?.driver) ?false : true)" style="cursor: pointer;"
                              class="material-icons-outlined" (click)="sendBackToAdmin(trips)"
                              title="Send Back To Admin">
                              assignment_return
                            </span>

                            <span *ngIf="tripDateValidate && ((trips?.driver) ?false : true)  && !assignVehicle"
                              style="cursor: pointer;" class="material-icons-outlined" (click)="sendBackToAdmin(trips)"
                              title="Send Back To Admin">
                              assignment_return
                            </span>

                            <span *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)"
                              style="cursor: pointer;" class="material-icons-outlined" (click)="sendBackToAdmin(trips)"
                              title="Send Back To Admin">
                              assignment_return
                            </span>
                            <mat-checkbox class="tracktripsCheckbox" type="checkbox" [checked]="trips.checked"
                              style="position: relative; bottom: 9px; left: 0px;"
                              (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-12 col-md-12">
                      <form [formGroup]="_mFormGroupForTrackTrips">
                        <div class="row">
                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                              <input type="text" [ngModelOptions]="{standalone: true}" (keyup)="clearDriver(vehicleSearch[i],i,
                        getIndex(trips))" (keypress)="omit_special_char_And_String($event)" maxlength="12"
                                [(ngModel)]="vehicleSearch[i]" placeholder="Vehicle Number" matInput
                                [matAutocomplete]="vehicleNoAuto" (input)="searchFilter($event)" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                        == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #vehicleNoAuto="matAutocomplete"
                                (optionSelected)="vehicleSearchSubmit(trips,$event,getIndex(trips))">
                                <mat-option *ngFor="let vehicle of
                        vehicleListData | vehicleSearchFilter  : vehicleSearch[i]"
                                  style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="vehicle.registrationNo">{{vehicle.registrationNo}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                              <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="driverSearch[i]"
                                (keypress)="omit_special_char_And_String($event)" maxlength="30"
                                placeholder="Choose Driver" matInput [matAutocomplete]="driverName" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                        == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #driverName="matAutocomplete">
                                <mat-option *ngFor="let driver of
                          trips.driverList | vehicleDriverSearchFilter : driverSearch[i]" (onSelectionChange)="driverSearchSubmit(trips,driver,
                          getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="driver.name">{{driver.name}}</mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.tripVendorAssign.DeviceTripStatus == 'stopped'">
                            <mat-form-field appearance="outline" class="escort-tracktrip">
                              <mat-select placeholder="Escort User" [value]="trips?.tripVendorAssign?.escort?.id"
                                [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                                  == 'not started') ? false : true) ? true :
                                  (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                  getIndex(trips))">
                                <mat-option class="escort-checkIconHide"
                                  style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="trips.tripVendorAssign.escort?.id">
                                  {{trips.tripVendorAssign.escort?.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2 col-md-2" *ngIf="trips.tripVendorAssign.DeviceTripStatus !== 'stopped'">
                            <mat-form-field appearance="outline" class="escort-tracktrip"
                              [ngClass]="{'mandatory-escortv2': trips?.tripVendorAssign?.escort && isFieldHighlighted(trips?.tripVendorAssign?.escort?.id)}">
                              <mat-select placeholder="Escort User" [value]="trips?.escort?.id" [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                                      == 'not started') ? false : true) ? true :
                                      (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                      getIndex(trips))">
                                <mat-option class="escort-checkIconHide" *ngFor="let escort of
                                          escortListData"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="escort.id">
                                  {{escort.name}}
                                </mat-option>
                              </mat-select>
                              <p class="error-msg"
                                *ngIf="trips?.tripVendorAssign?.escort && showErrorMessage(trips?.tripVendorAssign?.escort?.id)">
                                Please select another escort.
                              </p>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType =='IN'">
                            <mat-form-field appearance="outline" class="escort-tracktrip">
                              <input placeholder="Travel time (min)" value="{{trips.tripTravelTime}}" type="text"
                                maxlength="4" (keypress)="discard_special_char_And_String($event)"
                                (keyup)="setTime($event,trips)" matInput>
                              <img matSuffix src="assets/images/save.svg" height="17"
                                style="margin-bottom: 22px; padding-right: 5px; cursor: pointer;" (click)="updateTravelTime(trips,
                        getIndex(trips))" matTooltip="Update">
                            </mat-form-field>
                          </div>


                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType
              =='OUT'">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Travel Time :
                              {{trips.tripTravelTime}}</div>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Trip Distance
                              :
                              {{trips.tripDistance | number :'1.2-2'}} Km</div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-sm-2 col-md-2"
                            *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                        getIndex(trips))" class="btn red-btn-solid-other" style="padding: 7px 36px;">
                              Set Driver & Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                        getIndex(trips))" class="btn red-btn-solid-other" style="padding: 7px 63px;">
                              Re-Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2"
                            *ngIf="['not started', 'stopped'].includes(trips.tripVendorAssign.DeviceTripStatus)">
                            <button class="btn red-btn-solid-other" (click)="removeVDAllocation(trips)"
                              *ngIf="tripDateValidate && !((trips?.driver) ?false : true)" style="padding: 7px 45px;">
                              Remove Allocation
                            </button>
                          </div>
                          <div class="col-sm-2 col-md-2" *ngIf="trips.tripVendorAssign.DeviceTripStatus == 'running'">
                            <button class="btn red-btn-solid-alloc" (click)="onReallocation(trips)"
                              style="padding: 7px 60px;">
                              Re-Allocation
                            </button>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-sm-12 col-md-12">
                            <table class="table table-track-trips m-0">
                              <thead>
                                <th>Employee Id</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Gender</th>
                                <th *ngIf="address">Address</th>
                                <th *ngIf="areaLandmark">Area</th>
                                <th *ngIf="areaLandmark">Landmark</th>
                                <th>Show Status </th>
                                <th>Distance</th>
                                <th>
                                  <span
                                    style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
                                    <span>Time</span>
                                    <button class="btn red-btn-solid-time" *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(trips,
                          getIndex(trips))">Update</button>
                                  </span>
                                </th>
                                <th *ngIf="tripDateValidate &&
                          ((trips?.driver) ?
                          true : false)" style="text-align: center;"> Send SMS</th>
                              </thead>

                              <tbody>
                                <tr *ngFor="let users of trips.tripUserList">
                                  <td> <span class="badge bfont"
                                      [ngStyle]="{'color': '#041D41', 'opacity': '0.7', 'background': users.userColor, 'border': '1px solid' + users.userColor}">
                                      {{users.tripUser.userInfoDetails.employeeId}}
                                    </span>
                                  </td>
                                  <td>{{users.tripUser.userInfoDetails.firstName}}</td>
                                  <td *ngIf="!showContact">{{users.tripUser.userInfoDetails.contactNumber.substring(0,
                                    1)}}*******{{users.tripUser.userInfoDetails.contactNumber.substring(8, 10)}}</td>
                                  <td *ngIf="showContact">
                                    {{users.tripUser.userInfoDetails.contactNumber}}
                                  </td>
                                  <td>
                                    {{users.tripUser.userInfoDetails.gender == null ? "NA" :
                                    users.tripUser.userInfoDetails.gender == 'Male' ? 'M' :
                                    users.tripUser.userInfoDetails.gender == 'Female' ? 'F' : 'O'}}
                                  </td>
                                  <!-- <td>
                                    {{users.landmark.area.area}}
                                  </td>
                                  <td *ngIf="!showHideAddress">
                                    {{users.landmark.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text" *ngIf="showHideAddress">
                                    {{users.landmark.landmark}}
                                  </td> -->
                                  <td class="landmark-wrap-text" *ngIf="address && !showHideAddress">
                                    {{ users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") + ", " +
                                    (users.landmark.landmark !== null ? users.landmark.landmark : "NA") :
                                    users.userLandmark !== null ?
                                    users.userLandmark.area.area + ", " + users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address}}
                                  </td>
                                  <td *ngIf="showHideAddress && mTitle == 'Address'">
                                    {{users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area.substring(0, 5) : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area.substring(0, 5) :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address.substring(0, 5)}}*********
                                  </td>
                                  <td *ngIf="areaLandmark">
                                    {{users.landmark.type == "C" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area : users.tripUser.userInfoDetails.pickUpDrop ==
                                    null ? "NA" : users.tripUser.userInfoDetails.pickUpDrop.area.area}}
                                  </td>
                                  <td *ngIf="showHideLandmark && mTitle == 'Landmark'">
                                    {{users.landmark.type == "C" ? (users.landmark.landmark !== null ?
                                    users.landmark.landmark.substring(0, 5) : "NA") : users.userLandmark
                                    !== null ? users.userLandmark.landmark.substring(0, 5) :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text" *ngIf="areaLandmark && !showHideLandmark">
                                    {{users.landmark.type == "C" ? users.landmark.landmark : users.userLandmark
                                    !== null ? users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark}}
                                  </td>
                                  <td>
                                    {{users.showStatus}}
                                  </td>
                                  <td>
                                    {{users.empTripDistance | number
                                    :'1.2-2'}} Km
                                  </td>
                                  <td *ngIf="trips.logType=='OUT'">
                                    {{users.pickupDropTime ?
                                    users.pickupDropTime.slice(0,5) :
                                    'NA'}}
                                  </td>
                                  <td>
                                    <input class="input-field me-1" placeholder="Time* (Eg: 00:00)"
                                      *ngIf="trips.logType =='IN'" (keypress)="omit_special_char_And_String1($event)"
                                      autocomplete="off" maxlength="5"
                                      value="{{users.pickupDropTime ?users.pickupDropTime.slice(0,5) : ''}}"
                                      (change)="onTimeValidate($event, users, trips, getIndex(trips))">
                                  </td>
                                  <td style="text-align: center;" *ngIf="tripDateValidate &&
                            ((trips?.driver) ?
                            true : false)">
                                    <button class="red-btn-solid-resend"
                                      (click)="resendOtp(users, trips)">Resend_OTP</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-md-2 mt-3">
              <button class="btn red-btn-solid-other" (click)="onSubmitAloc()">Submit Allocation
              </button>
            </div>
          </div>

          <!-- Allocated Trips End -->
          <!-- UnAllocated Trips -->


          <!-- <div *ngIf="unAllocatedTripFlag"> -->
          <!-- <div class="row">
              <div class="col-sm-5">
                <div class="d-flex justify-content-between mt-3" style="width: 250px;">
                  <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
                    placeholder="Search by EmpId/Name" [(ngModel)]="searchTrip">
                </div>
              </div>
              <div class="col-sm-7" style="padding:1rem">
                <button class="btn-solid-select-all" (click)="onSelectAll(unAllocatedTripArr, escortListData)">Select
                  All</button>
              </div>
            </div> -->

          <!-- <div class="row">
              <div class="col-3 pt-1" style="padding: 5px;">
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showHideAddress"
                  (valueChange)="showOrHideAddress($event)"></ui-switch>
                <span style="position: relative; right: -15px; top: 5px; font-size: 13px;">Show/Hide Landmark</span>
              </div>
              <div class="col-3 pt-1" style="padding: 5px;">
                <ui-switch style="left: 10px;" defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showContact"
                  (valueChange)="showOrHideContact($event)"></ui-switch>
                <span style="position: relative; right: -15px; top: 5px; font-size: 13px;">Show/Hide Contact</span>
              </div>
            </div> -->


          <!-- <div *ngFor="let trips of unAllocatedTripArr |
      allocatedTripFilter : searchTrip; let i=index;">
              <div class="card">
                <div class="card-body">

                  <div class="row"
                    style="background-color: #ddd !important;padding-top: 7px; margin-left: 1px;margin-right: 1px; height: 50px;">
                    <div class="col-3 pt-1" style="font-size: 13px;">Trip ID : <span class="trip-code"
                        (click)=maptrackview(trips.tripId)>{{trips.tripCode}}</span></div>
                    <div class="col-3 pt-1" style="font-size: 13px;">Trip Date : {{trips.tripDate}}</div>
                    <div class="col-3 pt-1" style="font-size: 13px;">Shift :
                      {{trips.logType}}-{{trips.shiftTime}}</div>
                    <div class="col-3 pt-1" style="font-size: 13px;">DeviceTripStatus :
                      {{trips.tripVendorAssign.DeviceTripStatus}}</div>
                    <div>
                      <mat-checkbox class="tracktripsCheckbox" type="checkbox" [checked]="trips.checked"
                        (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                    </div>

                  </div>



                  <form class="example-form" [formGroup]="_mFormGroupForTrackTrips">

                    <div class="row">
                      <div class="col-2">
                        <div style="font-size: 13px;">Vehicle
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                          {{trips.vehicleType}}</div>
                      </div>
                      <div class="col-2">
                        <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                          <input type="text" [ngModelOptions]="{standalone: true}" (keyup)="clearDriver(vehicleSearch[i],i,
                        getIndex(trips))" (keypress)="omit_special_char_And_String($event)" maxlength="12"
                            [(ngModel)]="vehicleSearchs[i]" placeholder="Vehicle number" matInput
                            [matAutocomplete]="vehicleNoAuto" (input)="searchFilter($event)" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                        == 'not started') ? false : true">
                          <img matSuffix src="assets/images/search.svg" height="27"
                            style="margin-top: -22px;padding-right: 5px;">
                          <mat-autocomplete #vehicleNoAuto="matAutocomplete">
                            <mat-option *ngFor="let vehicle of
                        vehicleListData | vehicleSearchFilter  : vehicleSearchs[i]" (onSelectionChange)="vehicleSearchSubmit(trips,vehicle,
                              getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                              [value]="vehicle.registrationNo">{{vehicle.registrationNo}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <div class="col-2">
                        <mat-form-field appearance="outline" class="customFieldInSideDriver">
                          <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="driverSearchs[i]"
                            (keypress)="omit_special_char_And_String($event)" maxlength="30" placeholder="Driver"
                            matInput [matAutocomplete]="driverName" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                        == 'not started') ? false : true">
                          <img matSuffix src="assets/images/search.svg" height="27"
                            style="margin-top: -22px;padding-right: 5px;">
                          <mat-autocomplete #driverName="matAutocomplete">
                            <mat-option *ngFor="let driver of
                          trips.driverList | vehicleDriverSearchFilter : driverSearchs[i]" (onSelectionChange)="driverSearchSubmit(trips,driver,
                          getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                              [value]="driver.name">{{driver.name}}</mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div> -->


          <!-- <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                        <button (click)="setDriverAndSendSMS(trips,escortListData,
                  getIndex(trips))" class="btn red-btn-solid-other " style="position:relative;left: 95px;">
                          Set Driver & Send SMS
                        </button>
                      </div> -->

          <!-- <div class="col-2 pt-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                        <button (click)="setDriverAndSendSMS(trips,escortListData,
                getIndex(trips))" class="btn red-btn-solid-other" style="position:relative;left: 82px;">
                          Re-Send SMS
                        </button>
                      </div> -->


          <!-- <div class="col-2 pt-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true) ">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 40px;">
                          Send Back To Admin
                        </button>
                      </div>

                      <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && ((trips?.driver) ?false : true)  && !assignVehicle">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 80px;">
                          Send Back To Admin
                        </button>
                      </div> -->
          <!-- 
                      <div class="col-2 pt-2"
                        *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                        <button class="btn red-btn-solid-other" (click)="sendBackToAdmin(trips)"
                          style="position:relative;left: 70px;">
                          Send Back To Admin
                        </button>
                      </div> -->
          <!-- <div class="col-2 pt-2">
                        <button class="btn red-btn-solid-other" (click)="removeVDAllocation(trips)"
                          *ngIf="tripDateValidate && !((trips?.driver) ?false : true)"
                          style="position:relative;left: 20px;">
                          Remove Allocation
                        </button>
                      </div> -->
          <!-- </div>


                    <div fxLayout="row">
                      <div class="col-2 pt-3" style="font-size: 13px;">Escort Trip :
                        {{trips.escortStatus}}</div>
                      <div class="col-2 pt-2">
                        <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                          <mat-select placeholder="Escort user" [value]="trips?.escort?.id" [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                                    == 'not started') ? false : true) ? true :
                                    (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                    getIndex(trips))">
                            <mat-option *ngFor="let escort of
                                        escortListData"
                              style=" font-size: 13px !important; font-weight: 400 !important;" [value]="escort.id">
                              {{escort.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-2 pt-2" *ngIf="trips.logType =='IN'">
                        <mat-form-field appearance="outline" class="customFieldInSideDriver">
                          <input placeholder="Travel time (min)" formControlName="travelTime"
                            value="{{trips.tripTravelTime}}" type="text" maxlength="4"
                            (keypress)="discard_special_char_And_String($event)" (keyup)="setTime($event,trips)"
                            matInput>
                          <mat-icon class="update-traveltime" matSuffix (click)="updateTravelTime(trips,
                            getIndex(trips))" matTooltip="Update now">save
                          </mat-icon>
                        </mat-form-field>
                      </div>


                      <div class="col-2 " *ngIf="trips.logType
                  =='OUT'">
                        <div class="vehicle-name" style="font-size: 13px;position: relative;left: 90px">Travel Time :
                          {{trips.tripTravelTime}}</div>
                      </div>

                      <div class="col-2 ">
                        <div class="vehicle-name" style="font-size: 13px;position: relative;left: 90px">Trip Distance
                          :
                          {{trips.tripDistance | number :'1.2-2'}} Km</div>
                      </div>
                      <div class="col-2 pt-2">
                        <button class="btn red-btn-solid-other" style="position: relative;left: 105px;"
                          (click)="getAudit(trips)">
                          Audit Log
                        </button>
                      </div>
                    </div>

                    <div fxLayout="row" style="overflow-y: auto; border: 1px solid #efe5e5; border-radius: 5px;">
                      <ng-container>
                        <table fxFlex class="responsive tdw-position-relative
          tdw-padding-6">
                          <thead class="tdw-bottom-line mat-color--grey-100 tdw-card-thin">
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
              tdw-padding-6 header-width1">
                              <ng-container>
                                EmployeeId
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
              tdw-padding-6 header-width1">
                              <ng-container>
                                Name
                              </ng-container>
                            </th>

                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
              tdw-padding-6 header-width1">
                              <ng-container>
                                Number
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
              tdw-padding-6 header-width1">
                              <ng-container>
                                Gender
                              </ng-container>
                            </th>

                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
              tdw-padding-6 header-width1">
                              <ng-container>
                                Area
                              </ng-container>
                            </th>

                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
              tdw-padding-6 header-width1">
                              <ng-container>
                                LandMark
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
              tdw-padding-6 header-width1">
                              <ng-container>
                                Show Status
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
              tdw-padding-6 header-width1">
                              <ng-container>
                                Distance
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-center sort-remove
              tdw-padding-6 header-width1">
                              <ng-container>
                                Time<button class="red-btn-solid-time" *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(trips,
                        getIndex(trips))">Update</button>
                              </ng-container>
                            </th>
                            <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
              tdw-padding-6 header-width1" *ngIf="tripDateValidate &&
              ((trips?.driver) ?
              true : false)">
                              <ng-container>
                                Send SMS
                              </ng-container>
                            </th>
                          </thead>
                          <tbody class="mat-color--white tdw-card-thin tdw-border-2">
                            <ng-container>
                              <tr *ngFor="let users of trips.tripUserList">
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.employeeId}}
                                  </ng-container>
                                </th>


                                <th class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.firstName}}
                                  </ng-container>
                                </th>
                                <th *ngIf="!showContact" class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.contactNumber.substring(0,
                                    1)}}*******{{users.tripUser.userInfoDetails.contactNumber.substring(8, 10)}}
                                  </ng-container>
                                </th>
                                <th *ngIf="showContact" class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.contactNumber}}
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.tripUser.userInfoDetails.gender}}
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.landmark.area.area}}
                                  </ng-container>
                                </th>

                                <th *ngIf="!showHideAddress" class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.landmark.landmark.substring(0, 5)}}*********
                                  </ng-container>
                                </th>
                                <th *ngIf="showHideAddress" class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.landmark.landmark}}
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.showStatus}}
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container>
                                    {{users.empTripDistance | number
                                    :'1.2-2'}} Km
                                  </ng-container>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800">
                                  <ng-container *ngIf="trips.logType=='OUT'">
                                    {{users.pickupDropTime ?
                                    users.pickupDropTime.slice(0,5) :
                                    'NA'}}
                                  </ng-container>
                                  <mat-form-field appearance="outline" class="customFieldTime"
                                    *ngIf="trips.logType =='IN'">
                                    <input placeholder="Time*(Eg: 00:00)" matInput
                                      (keypress)="omit_special_char_And_String1($event)" autocomplete="off"
                                      maxlength="5"
                                      value="{{users.pickupDropTime ?users.pickupDropTime.slice(0,5) : ''}}"
                                      (change)="onTimeValidate($event, users, trips, getIndex(trips))">
                                  </mat-form-field>
                                </th>
                                <th class="tdw-bottom-line-light tdw-padding-10-6
                            tdw-font-12 tdw-font-open-sans tdw-font-normal
                            mat-color-text--grey-800" *ngIf="tripDateValidate &&
                            ((trips?.driver) ?
                            true : false)">
                                  <ng-container>
                                    <button class="red-btn-solid-resend"
                                      (click)="resendOtp(users, trips)">Resend_OTP</button>
                                  </ng-container>
                                </th>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </ng-container>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-2 d-flex">
              <button class="btn red-btn-solid-other" (click)="onSubmitAloc()">Submit Allocation
              </button>
            </div> -->
          <!-- </div> -->

          <!--New UnAllocated Trips -->

          <div class="tracktrips-scroll" *ngIf="unAllocatedTripFlag">
            <div class="row">
              <div class="col-sm-3 mt-3">
                <div class="d-flex justify-content-between" style="width: 250px;">
                  <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
                    placeholder="Search by EmpId/Name" [(ngModel)]="searchTrip">
                </div>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showOrHideAL"
                  [checked]="(showOrHideAL == true) ? true : false" style="position: relative; top: 10px;"
                  (valueChange)="showOrHideTitle($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  {{mTitle}}</span>
              </div>
              <div class="col-sm-2 mt-2">
                <span
                  style="position: relative; left: 7px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Address</span>
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="addressOrAL"
                  [checked]="(addressOrAL == true) ? true : false" style="position: relative; top: 10px; left: 18px;"
                  (valueChange)="showOrHideAddress($event)"></ui-switch>
                <span
                  style="position: relative; left: 30px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">A/L</span>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch style="position: relative; top: 10px;" defaultBoColor="#dfdfdf" color="#34c38f"
                  [(ngModel)]="showContact" [checked]="(showContact == true) ? true : false"
                  (valueChange)="showOrHideContact($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  Contact</span>
              </div>

              <div class="col-sm-3 mt-3">
                <button class="btn-solid-select-all" style="position: relative; right: 2px;"
                  (click)="onSelectAll(tripDetailsData, escortListData)">Select
                  All</button>
              </div>
            </div>

            <!-- loop starts here -->


            <div *ngFor="let trips of unAllocatedTripArr |
      allocatedTripFilter : searchTrip; let i=index;">
              <div class=" card p-0 mb-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12" style="background-color: #FBFCFF !important;padding-top: 10px; margin-left: 1px;margin-right: 1px; 
                      height: 45px; box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14), 
                      0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px -2px rgba(0, 0, 0, 0.2);">
                      <div class="summarytracktrips">
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            ID
                            : </span><span class="trip-code" [ngStyle]="trips.tripsColor ? 
                            {'color': '#041D41', 'opacity': '0.7', 'background': trips.tripsColor, 'border': '1px solid' + trips.tripsColor, 
                            'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'} : 
                            {'color': '#7C7D84', 'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'}"
                            (click)=maptrackview(trips.tripId)>{{getExtractedSubstring(trips.tripCode)}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Date
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripDate}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Shift
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.logType}}-{{trips.shiftTime}}</span>
                        </div>
                        <div>
                          <span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Vehicle
                            Type
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.vehicleType}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Status
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripVendorAssign.DeviceTripStatus}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Escort
                            Trip
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.escortStatus}}</span>
                        </div>

                        <div>
                          <span class="action-container">
                            <a href="javascript: void(0);" title="Audit" (click)="getAudit(trips)">
                              <img src="assets/images/icons/audit.svg">
                            </a>
                            <span *ngIf="tripDateValidate && !((trips?.driver) ?false : true)" style="cursor: pointer;"
                              class="material-icons-outlined" (click)="sendBackToAdmin(trips)"
                              title="Send Back To Admin">
                              assignment_return
                            </span>

                            <span *ngIf="tripDateValidate && ((trips?.driver) ?false : true)  && !assignVehicle"
                              style="cursor: pointer;" class="material-icons-outlined" (click)="sendBackToAdmin(trips)"
                              title="Send Back To Admin">
                              assignment_return
                            </span>

                            <span *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)"
                              style="cursor: pointer;" class="material-icons-outlined" (click)="sendBackToAdmin(trips)"
                              title="Send Back To Admin">
                              assignment_return
                            </span>
                            <mat-checkbox class="tracktripsCheckbox" type="checkbox" [checked]="trips.checked"
                              style="position: relative; bottom: 9px; left: 0px;"
                              (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-12 col-md-12">
                      <form [formGroup]="_mFormGroupForTrackTrips">
                        <div class="row">
                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                              <input type="text" [ngModelOptions]="{standalone: true}" (keyup)="clearDriver(vehicleSearch[i],i,
                        getIndex(trips))" (keypress)="omit_special_char_And_String($event)" maxlength="12"
                                [(ngModel)]="vehicleSearch[i]" placeholder="Vehicle Number" matInput
                                [matAutocomplete]="vehicleNoAuto" (input)="searchFilter($event)" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                        == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #vehicleNoAuto="matAutocomplete"
                                (optionSelected)="vehicleSearchSubmit(trips,$event,getIndex(trips))">
                                <mat-option *ngFor="let vehicle of
                        vehicleListData | vehicleSearchFilter  : vehicleSearch[i]"
                                  style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="vehicle.registrationNo">{{vehicle.registrationNo}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle">
                              <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="driverSearch[i]"
                                (keypress)="omit_special_char_And_String($event)" maxlength="30"
                                placeholder="Choose Driver" matInput [matAutocomplete]="driverName" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                        == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #driverName="matAutocomplete">
                                <mat-option *ngFor="let driver of
                          trips.driverList | vehicleDriverSearchFilter : driverSearch[i]" (onSelectionChange)="driverSearchSubmit(trips,driver,
                          getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="driver.name">{{driver.name}}</mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.tripVendorAssign.DeviceTripStatus == 'stopped'">
                            <mat-form-field appearance="outline" class="escort-tracktrip">
                              <mat-select placeholder="Escort User" [value]="trips?.tripVendorAssign?.escort?.id"
                                [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                                    == 'not started') ? false : true) ? true :
                                    (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                    getIndex(trips))">
                                <mat-option class="escort-checkIconHide"
                                  style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="trips.tripVendorAssign.escort?.id">
                                  {{trips.tripVendorAssign.escort?.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.tripVendorAssign.DeviceTripStatus !== 'stopped'">
                            <mat-form-field appearance="outline" class="escort-tracktrip"
                              [ngClass]="{'mandatory-escortv2': trips?.tripVendorAssign?.escort && isFieldHighlighted(trips?.tripVendorAssign?.escort?.id)}">
                              <mat-select placeholder="Escort User" [value]="trips?.escort?.id" [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                                      == 'not started') ? false : true) ? true :
                                      (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                      getIndex(trips))">
                                <mat-option class="escort-checkIconHide" *ngFor="let escort of
                                          escortListData"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="escort.id">
                                  {{escort.name}}
                                </mat-option>
                              </mat-select>
                              <p class="error-msg"
                                *ngIf="trips?.tripVendorAssign?.escort && showErrorMessage(trips?.tripVendorAssign?.escort?.id)">
                                Please select another escort.
                              </p>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType =='IN'">
                            <mat-form-field appearance="outline" class="escort-tracktrip">
                              <input placeholder="Travel time (min)" value="{{trips.tripTravelTime}}" type="text"
                                maxlength="4" (keypress)="discard_special_char_And_String($event)"
                                (keyup)="setTime($event,trips)" matInput>
                              <img matSuffix src="assets/images/save.svg" height="17"
                                style="margin-bottom: 22px; padding-right: 5px; cursor: pointer;" (click)="updateTravelTime(trips,
                        getIndex(trips))" matTooltip="Update">
                            </mat-form-field>
                          </div>


                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType
              =='OUT'">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Travel Time :
                              {{trips.tripTravelTime}}</div>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Trip Distance
                              :
                              {{trips.tripDistance | number :'1.2-2'}} Km</div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-sm-2 col-md-2"
                            *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                        getIndex(trips))" class="btn red-btn-solid-other" style="padding: 7px 36px;">
                              Set Driver & Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                        getIndex(trips))" class="btn red-btn-solid-other" style="padding: 7px 63px;">
                              Re-Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2"
                            *ngIf="['not started', 'stopped'].includes(trips.tripVendorAssign.DeviceTripStatus)">
                            <button class="btn red-btn-solid-other" (click)="removeVDAllocation(trips)"
                              *ngIf="tripDateValidate && !((trips?.driver) ?false : true)" style="padding: 7px 45px;">
                              Remove Allocation
                            </button>
                          </div>
                          <div class="col-sm-2 col-md-2" *ngIf="trips.tripVendorAssign.DeviceTripStatus == 'running'">
                            <button class="btn red-btn-solid-other" (click)="onReallocation(trips)"
                              style="padding: 7px 60px;">
                              Re-Allocation
                            </button>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-sm-12 col-md-12">
                            <table class="table table-track-trips m-0">
                              <thead>
                                <th>Employee Id</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Gender</th>
                                <th *ngIf="address">Address</th>
                                <th *ngIf="areaLandmark">Area</th>
                                <th *ngIf="areaLandmark">Landmark</th>
                                <th>Show Status </th>
                                <th>Distance</th>
                                <th>
                                  <span
                                    style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
                                    <span>Time</span>
                                    <button class="btn red-btn-solid-time" *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(trips,
                          getIndex(trips))">Update</button>
                                  </span>
                                </th>
                                <th *ngIf="tripDateValidate &&
                          ((trips?.driver) ?
                          true : false)" style="text-align: center;"> Send SMS</th>
                              </thead>

                              <tbody>
                                <tr *ngFor="let users of trips.tripUserList">
                                  <td> <span class="badge bfont"
                                      [ngStyle]="{'color': '#041D41', 'opacity': '0.7', 'background': users.userColor, 'border': '1px solid' + users.userColor}">
                                      {{users.tripUser.userInfoDetails.employeeId}}
                                    </span>
                                  </td>
                                  <td>{{users.tripUser.userInfoDetails.firstName}}</td>
                                  <td *ngIf="!showContact">{{users.tripUser.userInfoDetails.contactNumber.substring(0,
                                    1)}}*******{{users.tripUser.userInfoDetails.contactNumber.substring(8, 10)}}</td>
                                  <td *ngIf="showContact">
                                    {{users.tripUser.userInfoDetails.contactNumber}}
                                  </td>
                                  <td>
                                    {{users.tripUser.userInfoDetails.gender == null ? "NA" :
                                    users.tripUser.userInfoDetails.gender == 'Male' ? 'M' :
                                    users.tripUser.userInfoDetails.gender == 'Female' ? 'F' : 'O'}}
                                  </td>
                                  <!-- <td>
                                    {{users.landmark.area.area}}
                                  </td>
                                  <td *ngIf="!showHideAddress">
                                    {{users.landmark.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text" *ngIf="showHideAddress">
                                    {{users.landmark.landmark}}
                                  </td> -->
                                  <td class="landmark-wrap-text" *ngIf="address && !showHideAddress">
                                    {{ users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") + ", " +
                                    (users.landmark.landmark !== null ? users.landmark.landmark : "NA") :
                                    users.userLandmark !== null ?
                                    users.userLandmark.area.area + ", " + users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address}}
                                  </td>
                                  <td *ngIf="showHideAddress && mTitle == 'Address'">
                                    {{users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area.substring(0, 5) : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area.substring(0, 5) :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address.substring(0, 5)}}*********
                                  </td>
                                  <td *ngIf="areaLandmark">
                                    {{users.landmark.type == "C" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area : users.tripUser.userInfoDetails.pickUpDrop ==
                                    null ? "NA" : users.tripUser.userInfoDetails.pickUpDrop.area.area}}
                                  </td>
                                  <td *ngIf="showHideLandmark && mTitle == 'Landmark'">
                                    {{users.landmark.type == "C" ? (users.landmark.landmark !== null ?
                                    users.landmark.landmark.substring(0, 5) : "NA") : users.userLandmark
                                    !== null ? users.userLandmark.landmark.substring(0, 5) :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text" *ngIf="areaLandmark && !showHideLandmark">
                                    {{users.landmark.type == "C" ? users.landmark.landmark : users.userLandmark
                                    !== null ? users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark}}
                                  </td>
                                  <td>
                                    {{users.showStatus}}
                                  </td>
                                  <td>
                                    {{users.empTripDistance | number
                                    :'1.2-2'}} Km
                                  </td>
                                  <td *ngIf="trips.logType=='OUT'">
                                    {{users.pickupDropTime ?
                                    users.pickupDropTime.slice(0,5) :
                                    'NA'}}
                                  </td>
                                  <td>
                                    <input class="input-field me-1" placeholder="Time* (Eg: 00:00)"
                                      *ngIf="trips.logType =='IN'" (keypress)="omit_special_char_And_String1($event)"
                                      autocomplete="off" maxlength="5"
                                      value="{{users.pickupDropTime ?users.pickupDropTime.slice(0,5) : ''}}"
                                      (change)="onTimeValidate($event, users, trips, getIndex(trips))">
                                  </td>
                                  <td style="text-align: center;" *ngIf="tripDateValidate &&
                            ((trips?.driver) ?
                            true : false)">
                                    <button class="red-btn-solid-resend"
                                      (click)="resendOtp(users, trips)">Resend_OTP</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-md-2 mt-3">
              <button class="btn red-btn-solid-other" (click)="onSubmitAloc()">Submit Allocation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>