<div class="card" style="margin: 0px;">
  <div class="card-body" style="margin: 0px;">
    <h2 mat-dialog-title style="margin: 0px;padding: 0px;">
      <span class="material-icons-outlined"
        style="color: red; font-size: 22px; float: right; position: relative; cursor: pointer; bottom: 10px; left: 8px;"
        (click)="onClickCancel()"> close</span>
    </h2>
    <div *ngIf="employeeFlag" style="margin-bottom: 0px;margin-top: 0px;">
      <div class="row">
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Current Schedules</button>
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Upcoming Trips</button>
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Past Schedules</button>
      </div>
      <div class="row">
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Past Trips</button>
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Address</button>
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Geocode Change</button>
      </div>
    </div>

    <div *ngIf="vehicleFlag" style="margin-bottom: 0px;margin-top: 0px;">
      <div class="row">
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Past Trips</button>
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Upcoming Trips</button>
      </div>
      <div class="row">
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Running Trips</button>
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Documents</button>
      </div>
    </div>


    <div *ngIf="driverFlag" style="margin-bottom: 0px;margin-top: 0px;">
      <div class="row">
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Upcoming Trips</button>
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Past Trips</button>
      </div>
      <div class="row">
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Running Trips</button>
        <button class="rectangleButton" style="color: #74788D;" (click)="openDialog($event)">Documents</button>
      </div>
    </div>
  </div>
</div>