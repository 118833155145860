import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbNavModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RoutingRoutingModule } from './routing-routing.module';
import { MasterRoutingComponent } from './master-routing/master-routing.component';
import { ShiftMappingLayoutComponent } from './shift-mapping-layout/shift-mapping-layout.component';
import { TripsComponent } from './trips/trips.component';
import { DeleteVendorAllocationComponent } from './delete-vendor-allocation/delete-vendor-allocation.component';
import { RoutingSetupLayoutComponent } from './routing-setup-layout/routing-setup-layout.component';
import { ManageMasterRouteComponent } from './manage-master-route/manage-master-route.component';
import { AdjustmentTripsComponent } from './adjustment-trips/adjustment-trips.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadAplComponent } from './master-routing/upload-apl/upload-apl.component';
import { BoundaryAuditComponent } from './master-routing/boundary-audit/boundary-audit.component';
import { LandmarkComponent } from './master-routing/landmark/landmark.component';
import { ZoneRenameComponent } from './master-routing/zone-rename/zone-rename.component';
import { UploadAplStatusComponent } from './master-routing/upload-apl-status/upload-apl-status.component';
import { AreaComponent } from './master-routing/area/area.component';
import { ZoneShiftMappingComponent } from './shift-mapping-layout/zone-shift-mapping/zone-shift-mapping/zone-shift-mapping.component';
import { ShiftPickupPointMappingComponent } from './shift-mapping-layout/shift-pickup-point-mapping/shift-pickup-point-mapping/shift-pickup-point-mapping.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgChartsModule } from 'ng2-charts';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TrackTripsComponent } from './track-trips/track-trips.component';
import { RoutingSetupComponent } from './routing-setup-layout/routing-setup/routing-setup/routing-setup.component';
import { FixedRoutingSetupComponent } from './routing-setup-layout/fixed-routing-setup/fixed-routing-setup/fixed-routing-setup.component';
import { AddFixedRoutingComponent } from './routing-setup-layout/fixed-routing-setup/fixed-routing-setup/add-fixed-routing/add-fixed-routing/add-fixed-routing.component';
import { TripMapDialogComponent } from './track-trips/trip-map-dialog/trip-map-dialog.component';
import { PushBackToAdminComponent } from './track-trips/push-back-to-admin/push-back-to-admin.component';
import { RouteMapSetupComponent } from './routing-setup-layout/route-map-setup/route-map-setup/route-map-setup.component';
import { SpecialCategorySetupComponent } from './routing-setup-layout/special-category-setup/special-category-setup/special-category-setup.component';
import { DndModule } from "ng2-dnd";
import { PreviewDialogComponent } from './manage-master-route/preview-dialog/preview-dialog.component';
import { NavigationSetupComponent } from './routing-setup-layout/navigation-setup/navigation-setup.component';
import { TripWarningComponent } from './adjustment-trips/trip-warning/trip-warning.component';
import { OnCallBookingComponent } from './on-call-booking/on-call-booking.component';
import { OnCallBookingAllocComponent } from './on-call-booking/on-call-booking-alloc/on-call-booking-alloc.component';
import { RoutingErrorComponent } from './trips/routing-error/routing-error.component';
import { TripGenerationComponent } from './trips/trip-generation/trip-generation.component';
import { TripViewComponent } from './trips/trip-view/trip-view.component';
import { TripMirrorComponent } from './trips/trip-mirror/trip-mirror.component';
import { ExceptionComponent } from './trips/trip-mirror/exception/exception.component';
import { TripMirrorMapComponent } from './trips/trip-mirror/trip-mirror-map/trip-mirror-map.component';
import { ShowCategoryComponent } from './trips/trip-mirror/show-category/show-category.component';
import { TripMapComponent } from './trips/trip-view/trip-map/trip-map.component';
import { MissingEmployeeExceptionComponent } from './trips/trip-view/missing-employee-exception/missing-employee-exception.component';
import { TripUserTimeComponent } from './trips/trip-view/trip-user-time/trip-user-time.component';
import { TravelStatusComponent } from './trips/trip-view/travel-status/travel-status.component';
import { UploadTripStatusComponent } from './trips/trip-view/upload-trip-status/upload-trip-status.component';
import { ExceptionUsersComponent } from './trips/trip-view/exception-users/exception-users.component';
import { AllocateVendorComponent } from './trips/trip-view/allocate-vendor/allocate-vendor.component';
import { TripModifyComponent } from './trips/trip-view/trip-modify/trip-modify.component';
import { VehicleTypeCountComponent } from './trips/trip-view/vehicle-type-count/vehicle-type-count.component';
import { ModifyConfirmationComponent } from './trips/trip-view/trip-modify/modify-confirmation/modify-confirmation.component';
import { AllocateVendorRatioComponent } from './trips/trip-view/allocate-vendor-ratio/allocate-vendor-ratio.component';
import { TripEditZoneComponent } from './trips/trip-view/trip-edit-zone/trip-edit-zone.component';
import { AuditLogComponent } from 'src/app/shared/audit-log/audit-log/audit-log.component';
import { VendorTripViewComponent } from './vendor-trip-view/vendor-trip-view/vendor-trip-view.component';
import { VendorTripsComponent } from './vendor-trip-view/vendor-trip-view/vendor-trips/vendor-trips/vendor-trips.component';
import { AddEditRoutingSetupComponent } from './routing-setup-layout/routing-setup/routing-setup/add-edit-routing-setup/add-edit-routing-setup.component';
import { AdjustmentTripsVendorComponent } from './adjustment-trips-vendor/adjustment-trips-vendor.component';
import { AddVendorAdjustmentTripsComponent } from './adjustment-trips-vendor/add-vendor-adjustment-trips/add-vendor-adjustment-trips.component';
import { OnCallTripsComponent } from './on-call-trips/on-call-trips.component';
import { OnCallComponent } from './on-call/on-call.component';
import { OnCallMapComponent } from 'src/app/pages/routing/on-call-map/on-call-map.component';
import { OncallRestartComponent } from 'src/app/pages/routing/oncall-restart/oncall-restart.component';
import { AdminTrackTripsComponent } from './trips/trip-view/admin-track-trips/admin-track-trips.component';
import { PushBackAdminComponent } from './trips/trip-view/admin-track-trips/push-back-admin/push-back-admin.component';
import { TrackTripMapComponent } from './trips/trip-view/admin-track-trips/track-trip-map/track-trip-map.component';
import { VendorTracktripsRellocationComponent } from './track-trips/vendor-tracktrips-rellocation/vendor-tracktrips-rellocation.component';
import { VendorTrackTripsComponent } from './vendor-track-trips/vendor-track-trips.component';
import { AdminPushBackComponent } from './vendor-track-trips/admin-push-back/admin-push-back.component';
import { MapTripPopupComponent } from './vendor-track-trips/map-trip-popup/map-trip-popup.component';
import { ReallocationOfTripsComponent } from './vendor-track-trips/reallocation-of-trips/reallocation-of-trips.component';
import { ShowUnallocatedCountWarningComponent } from './vendor-track-trips/show-unallocated-count-warning/show-unallocated-count-warning.component';
import { AdminTrackTripsV3Component } from './trips/trip-view/admin-track-trips-v3/admin-track-trips-v3.component';
import { PushBackAdminV3Component } from './trips/trip-view/admin-track-trips-v3/push-back-admin-v3/push-back-admin-v3.component';
import { TrackTripMapV3Component } from './trips/trip-view/admin-track-trips-v3/track-trip-map-v3/track-trip-map-v3.component';
import { ShowUnallocateCountComponent } from './trips/trip-view/admin-track-trips-v3/show-unallocate-count/show-unallocate-count.component';
import { AddAdhocVehicleComponent } from './trips/trip-view/add-adhoc-vehicle/add-adhoc-vehicle.component';
import { AddoutsourcedvehicleComponent } from './vendor-track-trips/addoutsourcedvehicle/addoutsourcedvehicle.component';
import { AddOutsourceVehV2Component } from './track-trips/add-outsource-veh-v2/add-outsource-veh-v2.component';
import { V3AddAdhocVehComponent } from './trips/trip-view/admin-track-trips-v3/v3-add-adhoc-veh/v3-add-adhoc-veh.component';



@NgModule({
  declarations: [
    MasterRoutingComponent,
    ShiftMappingLayoutComponent,
    TripsComponent,
    DeleteVendorAllocationComponent,
    RoutingSetupLayoutComponent,
    ManageMasterRouteComponent,
    AdjustmentTripsComponent,
    UploadAplComponent,
    BoundaryAuditComponent,
    LandmarkComponent,
    ZoneRenameComponent,
    UploadAplStatusComponent,
    AreaComponent,
    ZoneShiftMappingComponent,
    ShiftPickupPointMappingComponent,
    TrackTripsComponent,
    RoutingSetupComponent,
    FixedRoutingSetupComponent,
    AddFixedRoutingComponent,
    TripMapDialogComponent,
    PushBackToAdminComponent,
    RouteMapSetupComponent,
    SpecialCategorySetupComponent,
    PreviewDialogComponent,
    NavigationSetupComponent,
    TripWarningComponent,
    OnCallBookingComponent,
    OnCallBookingAllocComponent,
    RoutingErrorComponent,
    TripGenerationComponent,
    TripViewComponent,
    TripMirrorComponent,
    ExceptionComponent,
    TripMirrorMapComponent,
    ShowCategoryComponent,
    TripMapComponent,
    MissingEmployeeExceptionComponent,
    TripUserTimeComponent,
    TravelStatusComponent,
    UploadTripStatusComponent,
    ExceptionUsersComponent,
    AllocateVendorComponent,
    TripModifyComponent,
    VehicleTypeCountComponent,
    ModifyConfirmationComponent,
    AllocateVendorRatioComponent,
    TripEditZoneComponent,
    VendorTripViewComponent,
    VendorTripsComponent,
    AddEditRoutingSetupComponent,
    AdjustmentTripsVendorComponent,
    AddVendorAdjustmentTripsComponent,
    OnCallTripsComponent,
    OnCallComponent,
    OnCallMapComponent,
    OncallRestartComponent,
    AdminTrackTripsComponent,
    PushBackAdminComponent,
    TrackTripMapComponent,
    VendorTracktripsRellocationComponent,
    VendorTrackTripsComponent,
    AdminPushBackComponent,
    MapTripPopupComponent,
    ReallocationOfTripsComponent,
    ShowUnallocatedCountWarningComponent,
    AdminTrackTripsV3Component,
    PushBackAdminV3Component,
    TrackTripMapV3Component,
    ShowUnallocateCountComponent,
    AddAdhocVehicleComponent,
    AddoutsourcedvehicleComponent,
    AddOutsourceVehV2Component,
    V3AddAdhocVehComponent
  ],
  imports: [
    CommonModule,
    RoutingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    FlexLayoutModule,
    MaterialModule,
    SharedModule,
    NgbTooltipModule,
    NgbPaginationModule,
    NgbNavModule,
    NgChartsModule,
    NgbDropdownModule,
    VirtualScrollerModule,
    AmazingTimePickerModule,
    DndModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    DndModule.forRoot(),
    UiSwitchModule.forRoot({ size: 'small' })
  ],
  entryComponents: [
    TripWarningComponent,
    TripGenerationComponent,
    TripMapDialogComponent,
    ShowCategoryComponent,
    TripMirrorMapComponent,
    UploadTripStatusComponent,
    RoutingErrorComponent,
    TripUserTimeComponent,
    MissingEmployeeExceptionComponent,
    ModifyConfirmationComponent,
    ExceptionUsersComponent,
    AuditLogComponent,
    TravelStatusComponent,
    ExceptionComponent,
    VehicleTypeCountComponent
  ],
})
export class RoutingModule { }
