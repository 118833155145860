export const RouterConstants = {
    default: '',
    auth: 'auth',
    client: 'client',
    changePassword: 'change-password',
    login: 'login',
    signup: 'signup',
    sso: 'sso',
    resetPassword: 'reset-password',
    resetpasswordsuccess: 'resetpasswordsuccess',
    passwordsuccess: 'passwordsuccess',
    resetpasswordchange: 'resetpasswordchange/:id',
    layoutContainer: 'myatom',
    newRegister: 'newaccount',
    addUserDetails: 'adduserdetails/:id',
    empVendor: 'multilogin',
    dashboard: {
        baseUrl: 'dashboard',
    },
    dashboardAnalytics: {
        baseUrl: 'dashboardAnalytics',
        behavioural: 'behavioural'
    },
    dashboardEmail: {
        baseUrl: 'dashboard-email'
    },
    adminConsole: {
        baseUrl: 'settings',
        setup: 'setup',
        company: 'company',
        city: 'city',
        site: 'site',
        shiftTime: 'shift-time-setup',
        project: 'project-setup',
        department: 'department-setup',
        costCenter: 'cost-center-setup',
        subscription: 'subscription-setup',
        booking: 'booking-setup',
        escalation: 'escalation-matrix',
        geocode: 'geo-code-setup',
        timeSlots: 'time-slots-setup',
        holidayList: 'holiday-list-setup',
        tripMirroring: 'trip-mirroring-setup',
        cosentForm: 'consent-form',
        noShowSetup: 'no-show-setup',
        bannerUpload: 'banner-upload-setup',
        managementBroadcast: 'management-broadcast',
        safeDrop: 'safe-drop-setup'
    },
    configurations: {
        baseUrl: 'configurations',
        clients: 'clients-config',
        clientsdb: 'clients-db-config',
        cronJob: 'cron-job-config',
        device: 'device-config',
        emailKey: 'email-key-config',
        emailSms: 'email-sms-templates',
        ivrKey: 'ivr-key-config',
        mapKey: 'map-key-config',
        payRoll: 'pay-roll-config',
        pwKey: 'pw-key-config',
        roleAuthorization: 'role-authorization',
        smsKey: 'sms-key-config',
        bulkEmail: 'bulk-email-trigger'

    },
    liveTracking: {
        default: '',
        baseUrl: 'live-tracking',
        liveTrack: 'live',
        panicStop: 'panic-stop',
        safeDropAction: 'safe-drop-action',
    },
    operations: {
        baseUrl: 'operations',
        booking: 'booking',
        driver: 'driver',
        escort: 'escort',
        geocode: 'geocode',
        roster: 'roster',
        subscription: 'subscription-layout',
        roleDelegation: 'role-delegation',
        user: 'user',
        vehicle: 'vehicle',
        vendors: 'vendors',
        vendor: {
            baseUrl: 'vendors',
            vendorMaster: 'vendor-master',
            vendor: 'vendor',
            vendorMasterInfo: 'vendor-master-info',
            add: 'add',
            edit: 'edit'
        },
        specialBooking: 'special-booking-al'
    },
    routing: {
        baseUrl: 'routing',
        adjustmentTrips: 'adjustment-trips',
        vendoradjustmenttrips: 'vendor-adjustment-trips',
        deleteVendor: 'delete-vendor-allocated-trips',
        manageMaster: 'manage-master-route',
        masterRouting: 'master-routing',
        routingSetup: 'routing-setup',
        shiftMapping: 'shift-mapping',
        trips: 'trips',
        trackTrips: 'track-trips',
        onCallBooking: 'on-call-booking',
        vendorTripView: 'vendor-trip-view',
        onCallTrips: 'on-call-trips',
        onCall: 'on-call',
        newTrackTrips: 'track-trips-v3'
    },
    reports: {
        baseUrl: 'reports',
        safeDropSummaryReport: 'safe-drop-summary',
        userExperienceReport: 'user-experience-report',
        adhocBooking: 'adhoc-booking-report',
        driverApp: 'driver-app-report',
        emailSmsCount: 'email-sms-count-report',
        employeeNoShow: 'employee-no-show-report',
        employeeAppUsers: 'employee-app-users-report',
        escort: 'escort-report',
        escortCost: 'escort-cost-allocation-report',
        exception: 'exception-report',
        fullBilling: 'full-billing-report',
        geoCode: 'geocode-report',
        scheduling: 'scheduling-report',
        medicalEmployee: 'medical-employee-report',
        panicAction: 'panic-action-report',
        noShow: 'no-show-report',
        tripPushBack: 'trip-push-back-report',
        OBEmployeeReport: 'out-of-boundary-report',
        tripStatementReport: 'trip-statement-report',
        tripreport: 'trip-report',
        tripUsuage: 'trip-Usuage-report',
        vehicleUsuage: 'vehicle-Usuage-report',
        ivrcalls: 'ivr-call-report',
        punchInOut: 'punch-in-out-report',
        otaandotd: 'ota-&-otd-report',
        onCallBooking: 'on-call-booking',
        vehicleMis: 'vehicle-mis-report',
        tripSheet: 'trip-sheet-report',
        autoStopReport: 'auto-stop-report',
        subscription: 'subscription',
        tripAdjustment: 'trip-adjustment-report',
        unsubscription: 'unsubscription',
        vehicleOccupancy: 'vehicle-occupancy-report',
        safeDrop: 'safe-drop-report',
        bookingCancelReport: 'bookig-cancellation-report',
        tripCancelReport: 'trip-cancellation-report',
        consentFormReport: 'consent-form-report',
        billingExceptionReport: 'general-billing-exception-report',
        lateNightBookingReport: 'late-night-booking-report',
        onCallBillingExceptionReport: 'oncall-billing-exception-report',
        tripAdditionReport: 'trip-addition-report',
        vehicleOverSpeedReport: 'vehicle-overspeed-report',
        ncns: 'ncns-report',
        tripTimeReport: 'trip-time-report',
        noShowTripReport: 'no-show-trip-report',
        safeDropIncident: 'safe-drop-incident-report',
        capacityUtilization: 'capacity-utilization-report',
        tripRatingReport: 'trip-rating-report',
        capacityUtilizationSummary: 'capacity-utilization-summary',
        vehicleReallocation:'vehicle-reallocation',
        tripDetailed:'trip-detailed',
        tripSummary:'trip-summary',
    },
    userProfile: {
        default: '',
        baseUrl: 'user',
        myProfile: 'my-profile',
        changePassword: 'change-password'
    },
    billing: {
        default: '',
        baseUrl: 'billing',
        billingException: 'billing-exception',
    }, 
    vendorDashboard: {
        baseUrl: 'vendordashboard',
    },
}