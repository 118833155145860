import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouterConstants } from 'src/app/core/constants/router.constants';
import { AdjustmentTripsComponent } from './adjustment-trips/adjustment-trips.component';
import { DeleteVendorAllocationComponent } from './delete-vendor-allocation/delete-vendor-allocation.component';
import { ManageMasterRouteComponent } from './manage-master-route/manage-master-route.component';
import { MasterRoutingComponent } from './master-routing/master-routing.component';
import { RoutingSetupLayoutComponent } from './routing-setup-layout/routing-setup-layout.component';
import { ShiftMappingLayoutComponent } from './shift-mapping-layout/shift-mapping-layout.component';
import { TrackTripsComponent } from './track-trips/track-trips.component';
import { TripsComponent } from './trips/trips.component';
import { ZoneShiftMappingComponent } from './shift-mapping-layout/zone-shift-mapping/zone-shift-mapping/zone-shift-mapping.component';
import { ShiftPickupPointMappingComponent } from './shift-mapping-layout/shift-pickup-point-mapping/shift-pickup-point-mapping/shift-pickup-point-mapping.component';
import { RoutingSetupComponent } from './routing-setup-layout/routing-setup/routing-setup/routing-setup.component';
import { FixedRoutingSetupComponent } from './routing-setup-layout/fixed-routing-setup/fixed-routing-setup/fixed-routing-setup.component';
import { AddFixedRoutingComponent } from './routing-setup-layout/fixed-routing-setup/fixed-routing-setup/add-fixed-routing/add-fixed-routing/add-fixed-routing.component';
import { RouteMapSetupComponent } from './routing-setup-layout/route-map-setup/route-map-setup/route-map-setup.component';
import { SpecialCategorySetupComponent } from './routing-setup-layout/special-category-setup/special-category-setup/special-category-setup.component';
import { NavigationSetupComponent } from './routing-setup-layout/navigation-setup/navigation-setup.component';
import { OnCallBookingComponent } from './on-call-booking/on-call-booking.component';
import { TripViewComponent } from './trips/trip-view/trip-view.component';
import { TripMirrorComponent } from './trips/trip-mirror/trip-mirror.component';
import { AllocateVendorComponent } from './trips/trip-view/allocate-vendor/allocate-vendor.component';
import { TripModifyComponent } from './trips/trip-view/trip-modify/trip-modify.component';
import { TripEditZoneComponent } from './trips/trip-view/trip-edit-zone/trip-edit-zone.component';
import { VendorTripViewComponent } from './vendor-trip-view/vendor-trip-view/vendor-trip-view.component';
import { VendorTripsComponent } from './vendor-trip-view/vendor-trip-view/vendor-trips/vendor-trips/vendor-trips.component';
import { AddEditRoutingSetupComponent } from './routing-setup-layout/routing-setup/routing-setup/add-edit-routing-setup/add-edit-routing-setup.component';
import { AdjustmentTripsVendorComponent } from './adjustment-trips-vendor/adjustment-trips-vendor.component';
import { OnCallTripsComponent } from './on-call-trips/on-call-trips.component';
import { OnCallComponent } from './on-call/on-call.component';
import { AdminTrackTripsComponent } from './trips/trip-view/admin-track-trips/admin-track-trips.component';
import { VendorTrackTripsComponent } from './vendor-track-trips/vendor-track-trips.component';
import { AdminTrackTripsV3Component } from './trips/trip-view/admin-track-trips-v3/admin-track-trips-v3.component';


const routes: Routes = [
  { path: RouterConstants.routing.adjustmentTrips, component: AdjustmentTripsComponent },
  { path: RouterConstants.routing.vendoradjustmenttrips, component: AdjustmentTripsVendorComponent },
  { path: RouterConstants.routing.deleteVendor, component: DeleteVendorAllocationComponent },
  { path: RouterConstants.routing.manageMaster, component: ManageMasterRouteComponent },
  { path: RouterConstants.routing.masterRouting, component: MasterRoutingComponent },
  {
    path: RouterConstants.routing.routingSetup, component: RoutingSetupLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/:activeTab',
        pathMatch: 'full'
      },
      { path: "routing-setup", component: RoutingSetupComponent },
      { path: "add-edit-routing-setup", component: AddEditRoutingSetupComponent },
      { path: "add-edit-routing-setup/:userId", component: AddEditRoutingSetupComponent },
      { path: "fixed-routing-setup", component: FixedRoutingSetupComponent },
      { path: "add-fixed-routing-setup", component: AddFixedRoutingComponent },
      { path: "route-map-setup", component: RouteMapSetupComponent },
      { path: "special-category-setup", component: SpecialCategorySetupComponent },
      { path: "navigation-setup", component: NavigationSetupComponent },
    ]
  },
  {
    path: RouterConstants.routing.shiftMapping, component: ShiftMappingLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/:activeTab',
        pathMatch: 'full'
      },
      { path: "zone-shift-mapping", component: ZoneShiftMappingComponent },
      { path: "shift-pickup-point-mapping", component: ShiftPickupPointMappingComponent },
    ]
  },
  { path: RouterConstants.routing.trips, component: TripsComponent },
  { path: "tripview/:siteId/:shiftId/:date", component: TripViewComponent },
  { path: "tripmirror", component: TripMirrorComponent },
  { path: "allocatevendor/:siteId/:shiftId/:date", component: AllocateVendorComponent },
  { path: "tripmodify/:siteId/:shiftId/:date", component: TripModifyComponent },
  { path: "editzone/:tripId", component: TripEditZoneComponent },
  { path: RouterConstants.routing.trackTrips, component: TrackTripsComponent },
  { path: RouterConstants.routing.onCallBooking, component: OnCallBookingComponent },
  { path: RouterConstants.routing.vendorTripView, component: VendorTripViewComponent },
  { path: "vendertripview/:siteId/:shiftId/:date", component: VendorTripsComponent },
  { path: RouterConstants.routing.onCallTrips, component: OnCallTripsComponent },
  { path: RouterConstants.routing.onCall, component: OnCallComponent },
  { path: "assignvehicle/:siteId/:shiftId/:date/:logtype", component: AdminTrackTripsComponent },
  { path: RouterConstants.routing.newTrackTrips, component: VendorTrackTripsComponent },
  { path: "assignvehiclev3/:siteId/:shiftId/:date/:logtype", component: AdminTrackTripsV3Component },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingRoutingModule { }
