import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TripViewComponent } from '../trip-view.component';

@Component({
  selector: 'app-travel-status',
  templateUrl: './travel-status.component.html',
  styleUrls: ['./travel-status.component.scss']
})
export class TravelStatusComponent implements OnInit {
  public _mTitle = "Edit Travel Status";
  public _mFormGroup: FormGroup;
  public mShowStatus = new FormControl("", Validators.required);
  public mReason = new FormControl("", Validators.required);
  public mIsEdit = false;
  public mData: any;
  _mStatus = [{ key: 1, value: 'SHOW' }]
  empId: any;
  tripId: any;

  constructor(
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    public dialogRef: MatDialogRef<TravelStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TripViewComponent["tripdetails"]
  ) {
    this._mFormGroup = this.fb.group({
      mShowStatus: this.mShowStatus,
      mReason: this.mReason
    });
    this.mIsEdit = true;
  }

  ngOnInit() {
    this.empId = this.data.empId;
    this.tripId = this.data.tripId;
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this._mFormGroup.valid) {
      if (this.mIsEdit) {
        this.updateShowStatus();
      }
    } else {
      this.notifyService.showWarning("Some fields are empty", null);
    }
  }

  updateShowStatus() {
    let data = {
      "showStaus": this.mShowStatus.value,
      "noShowUpdateReason": this.mReason.value
    }
    let empId = this.empId;
    let tripCode = this.tripId;
    this.ngxLoader.start();
    this.apiService.doPutRequestWithResponse(ApiConstants.updateTravelShowStatus + empId + '&tripId=' + tripCode, data, success => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.dialogRef.close(success);
        this.ngxLoader.stop();
        this.notifyService.showSuccess(success.body.message, null);
      }
    }, error => {
      this.ngxLoader.stop();
    });
  }

  discard_special_char_And_String(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

}
