<div class="mat-modal-dialog trip-status-modal">
    <h2 mat-dialog-title>
        <span class="material-icons-outlined" style="color: #FF0000; font-size: 19px;">
            info</span> <span
            style="color: #FF455B; font-size: 14px; font-weight: 500; font-family: poppins; position: relative; right: 130px;">{{_mTitle}}.</span>

        <mat-icon style="color:#FF0000; float: right; cursor: pointer; position: relative; bottom: 5px;"
            (click)="onClickCancel()">close</mat-icon>
    </h2>
    <mat-dialog-content>
        <form class="example-form" [formGroup]="_mFormGroup">
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <label class="font-size-font" style="color: #000000;">ShowStatus<sup
                            class="mandatory">*</sup></label>
                    <mat-form-field appearance="outline" class="trip-status margin-right-8px">
                        <mat-select placeholder="Show Status" formControlName="mShowStatus" [multiple]="!mIsEdit">
                            <mat-option class="trip-status-option" *ngFor="let data of _mStatus"
                                [value]="data.value">{{data.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-6 col-md-6">
                    <label class="font-size-font" style="color: #000000;">Reason<sup class="mandatory">*</sup></label>
                    <input class="form-input" aria-label="Default select example" placeholder="Reason" maxlength="50"
                        autocomplete="off" (keypress)="discard_special_char_And_String($event)"
                        formControlName="mReason" required>
                </div>
                <div mat-dialog-actions align="end">
                    <button (click)="onSubmit()" class="btn footerBtn submit-btn-solid ">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</div>