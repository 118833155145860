import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-show-unallocate-count',
  templateUrl: './show-unallocate-count.component.html',
  styleUrls: ['./show-unallocate-count.component.scss']
})
export class ShowUnallocateCountComponent {

  constructor(public dialogRef: MatDialogRef<ShowUnallocateCountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }
}
